import React from 'react';
import { FormGroup } from "reactstrap";
import JsonSchemaTreeEditor from '../JsonSchemaTreeEditor';


function JsonSchemaField(props) {
    const {
        formData,
        idSchema: {$id},
        schema: {
            title
        },
        uiSchema: {
            'ui:readonly': readonly
        },
        disabled,
        required,
        onChange
    } = props;

    return (<FormGroup disabled={readonly || disabled}>
        {title !== " " ? <label className="control-label" htmlFor={$id}>
            {title}{required ? <span className="required">*</span> : null}
        </label> : null}
        <JsonSchemaTreeEditor hideRoot schema={formData} readonly={readonly} onChange={onChange} />
    </FormGroup>);
}

export default JsonSchemaField;