import { useCallback, useState, useMemo } from "react";
import { Button } from "reactstrap";
import JsonSchemaTypeSelector from "./JsonSchemaTypeSelector";
import JsonStringEditor from "../JsonTreeEditor/StringEditor";
import JsonTreeEditor from "../JsonTreeEditor";


function JsonSchemaTreeNode({
    path,
    hideRoot,
    name, fixedName,
    readonly,
    schema,
    dispatchAction
}){
    const {
        type,
        properties,
        itemsSchema,
        otherProps
    } = useMemo(() => {
        const {
            type,
            properties,
            items: itemsSchema,
            ...otherProps
        } = schema;

        return { type, properties, itemsSchema, otherProps }
    }, [schema]);

    const otherPropsCount = Object.keys(otherProps).length;

    const hasChildren = type === "object" || type === "array";
    const pathPrefix = path ? `${path}.` : '';
    const [showAttrs, setShowAttrs] = useState();
    const [collapsed, setCollapsed] = useState();

    const onChangeType = useCallback((newType) => {
        dispatchAction.changeType(path, newType)
    }, [path, dispatchAction]);

    const onAddProperty = useCallback(() => {
        dispatchAction.addPropertyToObjectSchema(path)
    }, [path, dispatchAction])

    const onDelete = useCallback(() => {
        dispatchAction.deleteObjectAt(path);
    }, [path, dispatchAction])

    const onAttributeChange = useCallback((attrObj) => {
        dispatchAction.setObjectAttributes(path, attrObj);
    }, [path, dispatchAction, type, properties, itemsSchema]);

    const onChangeName = useCallback((path, newName) => {
        dispatchAction.renameObjectSchemaProperty(path, newName);
    }, [path, dispatchAction]);

    const isRoot = !name;

    return (<div className={`json-schema-tree-node ${isRoot ? 'root' : ''} ${hideRoot ? 'hide-root' : ''}`}>
        {hideRoot && isRoot ? null : (<div className='jstn-header'>
            {(!name || fixedName || readonly) ? (<div className='name'>
                {name || "(root)"}
            </div>) : (<span className="input-group">
                <JsonStringEditor path={path} node={name} setObject={onChangeName} allowMultiline={false} hasButtons />
                <Button className="delete-btn" type="button" color="light"
                    onClick={onDelete}
                    title="Remove Property"
                ><i className="fa fa-times" /></Button>
            </span>)}
            <JsonSchemaTypeSelector readonly={readonly} type={type} onChangeType={onChangeType} />
            <Button className="attribs-btn" type="button"
                color={`secondary ${showAttrs ? "active" : ""}`}
                onClick={() => setShowAttrs(!showAttrs)}
                title="Toggle Attributes"
            >
                <i className="fa fa-th-list" />{otherPropsCount ? `(${otherPropsCount})` : null}
            </Button>
            {hasChildren ? <Button className="attribs-btn" type="button"
                color="secondary"
                onClick={() => setCollapsed(!collapsed)}
                title="Toggle Properties"
            >
                <i className={`fa fa-chevron-${collapsed ? "right": "down"}`} />
            </Button> : null}
        </div>)}
        {showAttrs ? <div className="jstn-attributes">
            <div className="title">attributes</div>
            <JsonTreeEditor hideRoot mode="object" value={otherProps} onChange={onAttributeChange}/>
        </div> : null}
        {collapsed ? null : (
            type === "object" ? (
                <div className='jstn-children'>
                    {(Object.entries(properties).map(([propName, propSchema], idx) => (
                        <JsonSchemaTreeNode
                            key={idx}
                            name={propName}
                            path={`${pathPrefix}properties.${propName}`}
                            schema={propSchema}
                            readonly={readonly}
                            dispatchAction={dispatchAction}
                        />
                    )))}
                    <Button className="add-prop-btn" type="button" color="primary"
                        onClick={onAddProperty}
                        title="Add Property"
                    >+ Add Property</Button>
                </div>
            ) : type === "array" ? (
                <div className='jstn-children'>
                    <JsonSchemaTreeNode
                        name="(items)" fixedName
                        path={`${pathPrefix}items`}
                        schema={itemsSchema}
                        readonly={readonly}
                        dispatchAction={dispatchAction}
                    />
                </div>
            ) : null
        )}
    </div>)
}

export default JsonSchemaTreeNode;