import { t } from "@lingui/macro";
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';
import UWEEntityApi from "../../../api/UWEEntityApi";


export const ObjectSchemaEditor = FormComponent({
    ...parseFormDefinition({
        displayName: 'ObjectSchemaEditor',
        title: t`ObjectSchema Details`,
        schemaProps: {
            "title": [t`Object Schema`, " ${name}"].join(''),
            "type": "object",
            "ui:newSection": true,
            ":classNames": "grid-3-columns",
        },
        schema: {
            "info": {
                "title": "",
                ":classNames": "from-col-1-size-3 grid-3-columns",
                "type": "object",
                "properties": {
                    "name": {
                        "type": "string",
                        "title": "Name",
                        "map:field": "name"
                    },
                    "version": {
                        "type": "number",
                        "title": "Version",
                        "ui:readonly": true,
                        "map:field": "version"
                    },
                    "lastUpdated": {
                        "type": "string",
                        "format": "date",
                        "ui:readonly": true,
                        "map:field": "lastUpdated"
                    }
                }
            },
            "schemaSection": {
                "type": "object",
                "title": "Schema",
                "ui:expandable": true,
                ":classNames": "from-col-1-size-3",
                "properties": {
                    "schema": {
                        "type": "object",
                        "title": " ",
                        ":classNames": "from-col-1-size-3",
                        "ui:expandable": true,
                        "map:field": "schema",
                        "properties": {},
                        "ui:field": "JsonSchemaField"
                    }
                }
            },
            "metadata": {
                "type": "object",
                "title": "Metadata",
                "ui:expandable": true,
                ":classNames": "grid-2-columns from-col-1-size-3",
                "properties": {
                    "metadataObjectMap": {
                        "type": "object",
                        "title": " ",
                        "map:field": "metadataObjectMap",
                        "showTree": true,
                        "properties": {},
                        "additionalProperties": {
                            "type": ["object", "string"],
                            "hideDebug": true,
                            "hideScope": true,
                            "hideModal": true,
                            "ui:field": "JnxField"
                        },
                        "ui:layout": "vertical",
                        "ui:asPropertiesTable": true
                    },
                    "sidepanel": {
                        "type": "object",
                        "title": " ",
                        "properties": {
                            "test": {
                                "title": "Test Metadata",
                                "map:field": "__test__",
                                "ui:expandable": true,
                                "ui:expandedDefault": false,
                                ":classNames": "bordered grid-2-columns",
                                "ui:newSection": true,
                                "ui:sectionType": "subsection",
                                "type": "object",
                                "properties": {
                                    "input": {
                                        "type": "object",
                                        "title": "Entity Id",
                                        "ui:sectionType": "label",
                                        ":classNames": "from-col-1-size-2 flex-row",
                                        "properties": {
                                            "entityId": {
                                                "ui:hideLabel": true,
                                                "type": "number"
                                            },
                                            "button": {
                                                "title": t`Test`,
                                                "type": "number",
                                                "ui:field": "Button",
                                                "ui:loaderOnClick": true,
                                                "ui:onClick": {
                                                    "expr": `(
                                                        $id := sidepanel.test.input.entityId;
                                                        $mom := metadataObjectMap;
                                                        $resultPath := $path & ".sidepanel.test.result";
                                                        ($isTruthy($id) and $isTruthy($mom)) ? (
                                                            $result := $formContext.UWEEntityApi.previewMetadata({
                                                                "id": $id,
                                                                "metadataObjectMap": $mom
                                                            });
                                                            $set({$resultPath: $result});
                                                        )
                                                    )`,
                                                    "debug": "onclick",
                                                    "scope": "....."
                                                }
                                            },
                                        }
                                    },
                                    "result": {
                                        "title": t`Result`,
                                        "ui:sectionType": "label",
                                        ":classNames": "from-col-1-size-2",
                                        "type": ["object", "null"],
                                        "properties": {},
                                        "ui:readonly": true,
                                        "ui:showIf": {"expr": "$not($isEmpty(result))", "scope": "." },
                                        "ui:asPropertiesTable": true,
                                        "additionalProperties": {
                                            "type": "string",
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "rels": {
                "type": "object",
                "title": "Relations",
                ":classNames": "grid-2-columns from-col-1-size-3",
                "properties": {
                    "fwdRelations": {
                        "type": "array",
                        "ui:hideHeader": true,
                        "ui:readonly": true,
                        "ui:addable": false,
                        "ui:removable": false,
                        "ui:orderable": false,
                        "title": "Forward Relations",
                        "ui:sectionType": "label",
                        "map:array": "fwdRelations",
                        "items": {
                            "type": "object",
                            "properties": {
                                "path": {"type": "string", "title": " ", "map:field": "sourceProperty"},
                                "objectSchema": {
                                    "type": "number", "title": " ", "map:field": "targetId",
                                    "ui:field": "LookupFormField",
                                    "lookup": {
                                        "resource": "Entity Types",
                                        "api": "api:manage/objectschemas",
                                        "params": { "all": true, "size": 30 },
                                        "options": { "useCache": true },
                                        "jnx": "data.items",
                                        "id": "id",
                                        "label": "name"
                                    }
                                }
                            }
                        }
                    },
                    "backRelations": {
                        "type": "array",
                        "ui:hideHeader": true,
                        "ui:readonly": true,
                        "ui:addable": false,
                        "ui:removable": false,
                        "ui:orderable": false,
                        "title": "Back Relations",
                        "ui:sectionType": "label",
                        "map:array": "backRelations",
                        "items": {
                            "type": "object",
                            "properties": {
                                "objectSchema": {
                                    "type": "number", "title": " ", "map:field": "targetId",
                                    "ui:field": "LookupFormField",
                                    "lookup": {
                                        "resource": "Entity Types",
                                        "api": "api:manage/objectschemas",
                                        "params": { "all": true, "size": 30 },
                                        "options": { "useCache": true },
                                        "jnx": "data.items",
                                        "id": "id",
                                        "label": "name"
                                    }
                                },
                                "path": {"type": "string", "title": " ", "map:field": "sourceProperty"}
                            }
                        }
                    }
                }
            }
        },
    }),
    withoutNavigation: true,
    parseProps: ({ objectSchema, onChange }) => ({
        objectSchema,
        onObjectSchemaChange: onChange
    }),
    loadData: ({ objectSchema }) => objectSchema,
    buildFormContext: (...args) => ({
        UWEEntityApi
    }),
    hideDataLoader: true,
    onChange({
        formObject,
        parsedProps: {
            onObjectSchemaChange
        }
    }) {
        if (!formObject) return;
        onObjectSchemaChange(formObject);
    },
    onSubmit: ({ formData }) => Promise.resolve()
});

export default ObjectSchemaEditor;
