import { Button } from "reactstrap";
import Loader from "../../../../components/Loader";


function ComparisonTableHeaders({
    formIds = [],
    forms = {},
    loading = {},
    errorLoading = {},
    onRemoveFormId,
}){
    return <tr>
        <th colSpan={1} />
        {formIds.map(formId => (<th key={formId}>{
            loading[formId] ? (
                <Loader />
            ) : (errorLoading[formId] ? (
                <i className="fa fa-exclamation-triangle" title={`${errorLoading[formId] ?? "-"}`} />
            ) : (<>
                {forms[formId]?.name || `Form ${formId}`}
                <Button onClick={() => onRemoveFormId(formId)}>X</Button>    
            </>))
        }</th>))}
    </tr>;
}


export default ComparisonTableHeaders;