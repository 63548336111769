import React, { useRef, useState, useCallback } from "react";
import { FormGroup, Button } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import BrowserImageManipulation from 'browser-image-manipulation'


function SignatureField(props) {

    const {
        formData,
        title,
        classNames,
        penColor,
        $id,
        required,
        ref,
        editing, dirty,
        startEditing, acceptEditing, cancelEditing,
        clearSignature,
        onChange,
        width, height,
        resizeWidth, resizeHeight,
        isDisabled,
        attachSignature
    } = useSignatureFieldHooks(props);

    const changeHandler = (e) => {
        new BrowserImageManipulation()
            .loadBlob(e.target.files[0], {
                fixOrientation: true
            })
            .resize(resizeWidth, resizeHeight, {
                picaInit: { features: ['all'] }
            })
            .saveAsImage()
            .then(base64 => {
                attachSignature(base64);
            })
            .catch(e => console.error(e.toString()))
    };

    const uploadBtnId = `{$id}__file-upload`;

    return (
        <FormGroup disabled={isDisabled}>
            <div>
                <label className="control-label" htmlFor={$id}>
                    {title}{required ? <span className="required">*</span> : null}
                </label>
                <div className={`${classNames || ''} form-control signature`} style={{
                    width: `${width}px`,
                    height: `${height}px`,
                }}>
                    {editing ? (
                        <SignatureCanvas
                            ref={ref}
                            penColor={penColor}
                            canvasProps={{ width, height, className: "signature-pad writable" }}
                            disabled={isDisabled}
                            required={required}
                            onEnd={onChange}
                            value={formData}
                            backgroundColor="transparent"
                        />
                    ) : (formData ? (
                        <img src={formData} alt="firma" style={{
                            width: `${width}px`,
                            height: `${height}px`,
                        }} />
                    ) : (
                        <div className="no-signature">(Firma vacía)</div>
                    ))}
                    {isDisabled ? null : (<div className="button-bar tr">
                        {editing ? (<>
                            {dirty ? (<>
                                <Button onClick={clearSignature} disabled={isDisabled} title="Clear Signature">
                                    <i className="fa fa-trash" />
                                </Button>
                                <span className="gap" />
                            </>) : null}
                            <Button onClick={acceptEditing} disabled={isDisabled || !dirty} title="Accept New Signature">
                                <i className="fa fa-check text-success" />
                            </Button>
                            <Button onClick={cancelEditing} disabled={isDisabled} title="Cancel Edit Signature">
                                <i className="fa fa-times text-danger" />
                            </Button>
                        </>) : (<>
                            <Button onClick={(startEditing)} disabled={isDisabled} title="Edit Signature">
                                <i className="fa fa-edit" />
                            </Button>
                            <label htmlFor={uploadBtnId} disabled={isDisabled} className={`btn btn-primary ${isDisabled ? 'disabled' : ''}`}>
                                <i className="fa fa-upload" />
                            </label>
                        </>)}
                    </div>)}
                    <input id={uploadBtnId} disabled={isDisabled} onChange={changeHandler} type="file" />
                </div>
            </div>
        </FormGroup>
    );
}


function useSignatureFieldHooks(props) {

    const {
        formData,
        disabled,
        readonly: propReadonly,
        schema: {
            title,
        },
        uiSchema: {
            'ui:classNames': classNames,
            'ui:readonly': readonly,
            'akc:requiredIfVisible': requiredIfVisible,
            size = [300, 50],
            resize = [500, 60],
            penColor = 'blue',
        },
        idSchema: { $id },
        // title,
        required,
        onChange: propOnChange
    } = props;

    const ref = useRef();
    const [editing, setEditing] = useState(false);
    const [dirty, setDirty] = useState(false);

    const [width, height] = size;
    const [resizeWidth, resizeHeight] = resize;
    const isDisabled = readonly || disabled || propReadonly;

    const [attachImage, setAttachImage] = useState("");

    return {
        formData,
        title,
        classNames,
        penColor,
        $id,
        required: required || requiredIfVisible,
        ref,
        editing,
        dirty,
        startEditing() {
            setEditing(true);
            setDirty(false);
        },
        clearSignature() {
            setDirty(false);
            ref.current.clear();
        },
        cancelEditing() {
            setDirty(false);
            setEditing(false);
        },
        onChange() {
            setDirty(true);
        },
        async acceptEditing() {
            const data = ref.current.toDataURL("image/png")
            const blob = await fetch(data).then((res) => { console.log(res); return res.blob() });
            new BrowserImageManipulation()
                .loadBlob(blob)
                .resize(resizeWidth, resizeHeight)
                .saveAsImage({backgroundColor: 'transparent'})
                .then(base64 => {
                    propOnChange(base64);
                    setDirty(false);
                    setEditing(false);
                })
                .catch(e => console.error(e.toString()))
        },
        attachSignature(data) {
            propOnChange(data);
            setDirty(false);
            setEditing(false);
        },
        width,
        height,
        resizeWidth,
        resizeHeight,
        isDisabled,
        attachImage
    };
}

export default SignatureField;