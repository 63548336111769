import React, { useState, useRef, useMemo } from "react";
import {
    InputGroup,
    InputGroupText,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import useUID from "../util/useUID";


const ICONS = {
    pdf: "fa fa-file-pdf",
    doc: "fa fa-file-word",
    txt: "fa fa-file-lines",
    png: "fa fa-file-image",
    jpg: "fa fa-file-image",
    jpeg: "fa fa-file-image",
    zip: "fa fa-file-archive",
    default: "fa fa-file",
}


function FileInput({
    file,
    disabled,
    viewable = true,
    viewButtonText,
    uploadButtonText,
    onFileSelected,
    inlinePreview,
    resource = "File",
    accept = "*",
    readonly = false,
    required = false,
}) {
    const ref = useRef();
    const fieldId = useUID('fileinput-');

    const [clearCt, setClearCt] = useState(0);
    const [dragTarget, setDragTarget] = useState();
    const [modal, setModal] = useState(false);

    async function onChange({ target: { files: [selectedFile] } }) {
        if (disabled) return;
        if (selectedFile) {
            await onFileSelected(selectedFile);
            setClearCt(clearCt + 1);
        }
    }

    function onKeyPress(e) {
        if (disabled) return;
        if (e.which === 32 || e.which === 13) {
            e.stopPropagation();
            e.preventDefault();
            if (ref.current) ref.current.click();
        }
    }

    function onDrop(event) {
        if (disabled) return;
        setDragTarget(false);
        event.preventDefault();
        const { dataTransfer: { files: dtFiles, items: dtItems } } = event;
        const file = (dtItems ? Array.prototype.filter.call(dtItems, i => i.kind === 'file').map(i => i.getAsFile()) : dtFiles)[0];
        if (file) {
            onChange({ target: { files: [file] } });
        }
    }

    function onDragOver(event) {
        if (disabled) return;
        event.preventDefault();
        const { dataTransfer: { files: dtFiles, items: dtItems } } = event;
        const file = (dtItems ? Array.prototype.filter.call(dtItems, i => i.kind === 'file') : dtFiles)[0];
        setDragTarget(!!file);
    }

    function onDragLeave() {
        if (disabled) return;
        setDragTarget(false);
    }

    const extension = useMemo(() => ((file && file.name) || '').split('.').pop(), [file]);

    function modalToggle(transition = false) {
        setModal(transition)
    };

    const inputGroup = (
        <InputGroup
            className={`file-input ${dragTarget ? 'border-success' : ''}`}
            onDrop={!readonly ? onDrop : null}
            onDragOver={!readonly ? onDragOver : null}
            onDragLeave={!readonly ? onDragLeave : null}
        >
            {file && file.name ? (
                <InputGroupText title={file.name}><i className={ICONS[extension] || ICONS.default} />{' '}{file.name}</InputGroupText>
            ) : null}
            {viewable && file && file.url ? (
                <>
                    <Button  onClick={() => modalToggle(true)}>{viewButtonText || (<i className="fa fa-search" />)}</Button>
                    <FilePreviewModal isOpen={modal} toggle={() => modalToggle()} name={file.name} url={file.url} extension={extension} />
                </>
            ) : null}
            {!readonly ? (
                dragTarget ? (
                    <Button color="success" className="file-input-label">Drop File</Button>
                ) : (
                    <Button disabled={disabled} className="file-input-label" tag="label" htmlFor={fieldId} tabIndex="0" onKeyPress={onKeyPress}>
                        <input key={clearCt}
                            id={fieldId}
                            ref={ref}
                            type="file"
                            accept={accept} required={required && !(file && file.url)}
                            onChange={onChange}
                        />
                        {uploadButtonText || (<i className="fa fa-upload" />)}
                    </Button>
                )
            ) : null}
        </InputGroup>
    );



    return inlinePreview ? (<div className="file-input inline-preview">
        {file?.url ? <FilePreview url={file.url} extension={extension} /> : null}
        {inputGroup}
    </div>) : inputGroup;
}


function FilePreviewModal({
    isOpen, toggle, url, extension
}){
    return (<Modal isOpen={isOpen}
        toggle={toggle}
        className="modal-primary"
        size='xl'
        backdrop
    >
        <ModalHeader toggle={toggle} >
            Vista previa de Archivo
        </ModalHeader>
        <ModalBody style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <FilePreview url={url} extension={extension} />
        </ModalBody>
        <ModalFooter>
            <a className="btn btn-primary" href={url} target="_blank" rel="noreferrer" >Descargar</a>
            <Button onClick={toggle} color="secondary">Cerrar</Button>
        </ModalFooter>
    </Modal>);
}


function FilePreview({
    url, extension
}){
    const isImage = useMemo(() => /png|jpg|jpeg/i.test(extension), [extension]);
    return (isImage ?
    <img
        src={url}
        alt="File"
        width="75%"
        style={{alignSelf: 'center'}}
    /> :
    <iframe
        title="Files"
        src={`https://docs.google.com/gview?url=${url}&embedded=true`}
        height="500"
        width="100%"
        frameBorder="0"
    />);
}


export default FileInput;