import React from 'react';
import useResourceLoader from '../util/useResourceLoader';
import UWEEntityApi from '../api/UWEEntityApi';
import Loader from '../components/Loader';

const PROGRESS_CLASSES = {
    Unvisited : 'next_state',
    Current: 'current_state',
    Complete: 'complete_state'
};

function UWEEntityProgress({ entity, def }) {
    const { fromParent } = def;
    const [progressSteps, loadingSteps] = useResourceLoader(
        () => UWEEntityApi.getUWEEntityProgressSteps({ entityId: entity.id, fromParent }),
        [entity],
        [def]
    );

    return loadingSteps ? <Loader /> : (
        progressSteps.map((step, idx) => <ProgressStep
            key={idx} step={step} entity={entity}
        />)
    )
}


function ProgressStep({ step: {name, status, isFinalStep} }) {
    return <div className={`rowProgress ${isFinalStep ? 'is-last-state' : ''} ${PROGRESS_CLASSES[status] || ""}`}>{name}</div>
}

export default UWEEntityProgress;
