import { useEffect, useState } from "react";
import { wrapTextContent } from "../../util/svgUtil";


function useTextWrapping({ ref, content, maxWidth=200 }){
    const [lines, setLines] = useState();

    useEffect(() => {
        if (!ref.current) return;
        const newLines = wrapTextContent(content, ref.current.style, { maxWidth });
        if (!lines || lines.length !== newLines.length || lines.some((x, idx) => x !== newLines[idx])) {
            setLines(newLines);
        }
    }, [ref.current, content]);

    return lines;
}


export default useTextWrapping;
