import { t, Trans } from "@lingui/macro";
import React, { useMemo, useContext, useState } from 'react';
import {
    Table,
    Card,
    CardHeader
} from 'reactstrap';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../Loader';
import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import Notification from '../Notification';
import { getObject, interpolate, mapObject } from '../../util/mapObject';


function EntitySummary(props) {
    const { element, scope } = props;
    const [filters, setFilters] = useState([]);

    const auth = useContext(AuthContext);
    const {
        user: {
            user: { role: { extras } = {} } = {},
        } = {}
    } = auth;

    const lookup = useMemo(() => element.lookup || ({
        entity: element.entityType,
        api: !element.entityType && element.resource ? `api:${element.resource}` : null,
        params: element.lookupParams
    }), [element?.lookup, element]);

    function mergeParamsIntoFilters(filters, params) {
        if (!params || typeof params !== "object") {
            return filters;
        }

        Object.entries(params).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                filters[key] = value.filter(Boolean).map(String).join(",");
            } else if (value != null && typeof value !== "object") {
                filters[key] = String(value);
            }
        });

        return filters;
    }

    const [paging, loading, error] = useResourceLoader(async () => {
        const resource = lookup.entity ? `uwe-entities/${lookup.entity}/list` : lookup.api.split(':')[1];
        if (!resource) return null;
        mergeParamsIntoFilters(filters, lookup?.params);
        const data = await UWEEntityApi.getWorkTray({ resource, offset: 0, size: 20000, sortBy: "-date", ...filters });
        return Array.isArray(data) ? {
            from: 0,
            to: data.length,
            count: data.length,
            items: data
        } : data;

    }, [lookup]);

    const {
        from = 0,
        to = 0,
        count = 0,
        items: data = [],
    } = paging || {};

    const dataObject = element.columns.map((currData, idx) => {
        currData.total= 0
        data.forEach(elem => {
            const value = currData.mapKey ? mapObject(elem, { "value": currData.mapKey }).value : null;
            if (Array.isArray(currData.mapField)) {
                if (currData.mapField.includes(value)) {
                    currData.total += 1;
                }
            }
            else if(value > currData.mapField) {
                currData.total += 1;
            }  
        });
        return currData;
    });
    return (
        <Card className="inbox-card primary no-bottom-gap">
            <CardHeader>
            <div className="title">{element.title}</div>
            </CardHeader>
            <Table>
                <thead>
                    <tr>
                    {element.columns? element.columns.map(({ title, style, headerStyle }, idx) => (
                        <th key={idx} style={style || headerStyle} >{title}</th>
                    )): null}
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr><td colSpan="8">
                        <Loader centered><Trans>Loading {element.title}</Trans></Loader>
                    </td></tr>
                ):(
                    <tr>
                    {dataObject? dataObject.map(({ title, mapKey, total }, idx) => (
                        <td key={idx} >{total}</td>
                    )): null}
                        
                    </tr>
                )}
                </tbody>
            </Table>
        </Card>
    );
}

EntitySummary.rootSchema = {
    "definitions": {
        "mapFieldField": {
            "type": ["string", "object"],
            "ui:field": "JsonField",
            "showTree": true,
        },
    },
    "properties": {
        "title": {
            "title": "Title",
            "type": "string",
            "map:field": "title"
        },
        "entityType": {
            "title": "Entity Type",
            "type": "string",
            "map:field": "entityType",
            "ui:field": "LookupFormField",
            "lookup": {
                "resource": "Entity Types",
                "api": "api:manage/objectschemas",
                "params": { "all": true },
                "options": { "useCache": true },
                "jnx": "data.items",
                "id": "name",
                "label": "name"
            }
        },
        "lookupParams":{
            "title": "Params",
            "type": "object",
            "map:field": "lookupParams",
            "properties": {},
            "additionalProperties": {
                "type": "string",
                "title": "value"
            },
            "ui:asPropertiesTable": true
        },
        "columns": {
            "title": "Columns",
            ":classNames": "from-col-1-size-3",
            "type": "array",
            "map:array": "columns",
            "items": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "map:field": "title",
                        "title": "title"
                    },
                    "mapKey": {
                        "type": "string",
                        "map:field": "mapKey",
                        "title": "key"
                    },
                    "mapField": {
                        "$$include": "#/definitions/mapFieldField",
                        "map:field": "mapField",
                        "title": "Field"
                    },
                }
            }
        },
    }
}


export default EntitySummary;