import React, { useMemo, useState } from 'react';
import { Button as RSButton } from "reactstrap";
import { useJnx } from '../../util/jnx';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import { useOpenModal } from '../FormModal';
import Loader from '../Loader';
import getPathFromId from '../../util/getPathFromId';


function Button(props) {
    const {
        formContext,
        schema: {
            title,
            icon,
            tooltip,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:titleExpr': titleExpr,
            'ui:color': color,
            classNames: className,
            'ui:loaderOnClick': loaderOnClick,
            'ui:loaderText': loaderText,
            'ui:onClick': onClickExpr,
            'ui:simulateLabel': simulateLabel,
        } = {},
    } = props;

    const {
        setFormDataValues,
        sideChannel,
        bindings: fcBindings,
        // openModal,
    } = formContext;

    const openModal = useOpenModal();
    const [loading, setLoading] = useState();

    const path = useMemo(() => getPathFromId($id), [$id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];
    const titleJnx = useJnx(titleExpr);
    const bindings = useMemo(() => ({
        ...fcBindings,
        openModal,
        rootFormData,
        formObject,
        formContext,
    }), [openModal, rootFormData, formObject, fcBindings, formContext]);

    const text = useMemo(() => {
        return (
            (title || titleJnx?.eval(rootFormData, path, bindings))?.trim()
        );
    }, [
        title,
        titleJnx, path, rootFormData, bindings
    ]);

    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
        log: (...args) => console.log("jsonata console log\n", ...args)
    }), [setFormDataValues]);

    const onClick = useJnx(onClickExpr, {
        fn: async ([e], onClickJnx) => {
            e.preventDefault();
            if (onClickJnx) {
                if (loaderOnClick) setLoading(true);
                try {
                    await onClickJnx.evalAsync(rootFormData, path, bindings);
                } finally {
                    if (loaderOnClick) setLoading(false);
                }
            }
        },
        functionBinds
    }, [openModal, path, rootFormData, bindings, loaderOnClick, functionBinds]);

    return (<>
        {simulateLabel && <label>&nbsp;</label>}
        <RSButton
            className={`${className || ''} ${simulateLabel ? `disblock` : null}`}
            color={color}
            type="button"
            onClick={onClick}
            title={tooltip || undefined}
        >
            {loading ? <Loader sm>{loaderText}</Loader> : (<>
                {icon ? <i className={icon} /> : null}
                {icon && text ? " " : null}
                {text || null}
            </>)}
        </RSButton></>);
}


export default Button;