import { Trans } from '@lingui/macro';
import React, { useState } from "react";
import {
    Button,
    TabContent, TabPane,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import useSearchParams from "../../util/useSearchParams";

import UWEFormEditor from "./UWEFormEditor";

import JsonEditor from '../JsonEditor';

const TABS = {
    'form': {title: 'Formulario', component: UWEFormEditor},
    'json': {title: 'Json', component: JsonEditor},
    // 'sla': {title: 'SLA', component: SLASetting},
}

function FormBuilder({
    form,
    onChange,
    onReset,
    onSave
}){
    const [[tab, setTab]] = useSearchParams({view: 'form'});

    const {title, component: Component} = TABS[tab] || Object.values(TABS)[0];

    return (<>
        <div className="row">
            <div className="col-lg-3">
                <UncontrolledDropdown>
                    <DropdownToggle caret>{title}</DropdownToggle>
                    <DropdownMenu>{Object.entries(TABS).map(([key, {title}]) => (<DropdownItem key={key} onClick={() => setTab(key)}>
                        {title}
                    </DropdownItem>))}</DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div className="col-lg-6">
                <Button onClick={onSave} color="success"><Trans>Save</Trans></Button>
                <Button onClick={onReset} color="dark"><Trans>Reset</Trans></Button>
            </div>
        </div>
        <TabContent activeTab={tab}>
            {
                Component ? (
                    <TabPane tabId={tab}>
                        <Component
                            form={form} value={form}
                            onSaveForm={onSave}
                            onReset={onReset}
                            onChange={onChange}
                        />
                    </TabPane>
                ) : null
            }
        </TabContent>
    </>);
}


export default FormBuilder;