import React, { useState, useEffect, useMemo, useRef, useImperativeHandle, useCallback } from 'react';
import ScalableMovableSVG from './ScalableMovableSVG';
import useElementSizeCheckHook from '../../util/useElementSizeCheckHook';
// import './style.scss';
import getSVGXY from './getSVGXY';
import usePanZoom from './usePanZoom';
import Context2D from "./Context";


const SVGDrawer = (props, fref) => {
    const {
        className,
        children,
    } = props;

    const {
        ref,
        height,
        panning,
        onWheel,
        onImageMouseMove,
        onImageMouseDown,
        onRightClick,
        contextValue,
    } = useSVGDrawerHooks(props, fref);

    return (<div
        style={{
            position: 'relative',
            overflow: 'hidden',
            height: `${height}px`,
        }}
        ref={ref}
        tabIndex='-1'
    ><Context2D.Provider value={contextValue}><ScalableMovableSVG
        className={`${className} ${panning ? 'is-panning': ''}`}
        onWheel={onWheel}
        smoothTransitions={!panning}
        onMouseMove={onImageMouseMove}
        onMouseDown={onImageMouseDown}
        onRightClick={onRightClick}
    >
        {children}
    </ScalableMovableSVG></Context2D.Provider></div>);
};


function getEventXY(evt, viewport2DataCoords) {
    const point = getSVGXY(evt);
    const [x, y] = viewport2DataCoords([point.x, point.y], {toInt: true});
    return { x, y };
}


const useSVGDrawerHooks = (props, fref) => {
    const {
        src,
        onClick: prop_onClick = (() => { }),
        onRightClick: prop_onRightClick,
        onMouseMove,
        onKeyDown,
        width: propWidth,
        maxWidth: propMaxWidth,
        height: propHeight,
        onAddPolygon,
        setImageSize
    } = props;

    const [newRegion, setNewRegion] = useState([]);
    const [image, setImage] = useState();
    const [targetSize, setTargetSize] = useState([1, 1]);

    const canCloseRegion = () => newRegion.length >= 3;

    const closeRegion = async () => {
        await onAddPolygon(newRegion);
        setNewRegion([]);
    };

    useEffect(() => {
        function detectKeyDown({ target, keyCode }) {
            if (target !== ref.current) {
                return;
            }
            if (keyCode === 27 && newRegion.length) {
                setNewRegion([]);
            } else if (keyCode === 13 && canCloseRegion()) {
                closeRegion();
            } else if (onKeyDown) {
                onKeyDown({ keyCode });
            }
        }

        document.addEventListener('keydown', detectKeyDown);

        return () => {
            document.removeEventListener('keydown', detectKeyDown);
        };
    }, [canCloseRegion, newRegion]);

    useEffect(() => {
        if (src) {
            const img = new Image();
            img.src = src;
            img.addEventListener('load', () => setImage(img));
        }
    }, [src]);

    useEffect(() => {
        if(setImageSize) {
            setImageSize({ width: image?.width, height: image?.height })
        }
    }, [image])

    const ref = useElementSizeCheckHook((element) => {
        const { width: clientWidth } = element.getBoundingClientRect();
        const refWidth = Math.min(propWidth !== undefined ? propWidth : clientWidth, propMaxWidth ?? clientWidth);
        const refHeight = propHeight !== undefined ? propHeight : Infinity;
        const refSize = [refWidth, refHeight];

        if (refSize[0] !== targetSize[0] || refSize[1] !== targetSize[1]) {
            setTargetSize(refSize);
        }

    });

    const naturalWidth = Math.max(targetSize[0], 1);
    const naturalHeight = Math.max(targetSize[1], 1);

    const [pct, width, height] = useMemo(() => {
        const [pWidth, pHeight] = targetSize;
        if (naturalHeight * pWidth <= pHeight * naturalWidth) {
            const pctW = pWidth / naturalWidth;
            return [pctW, pWidth, naturalHeight * pctW];
        } else {
            const pctH = pHeight / naturalHeight;
            return [pctH, naturalWidth * pctH, pHeight];
        }
    }, [targetSize, naturalWidth, naturalHeight]);

    const onRightClick = prop_onRightClick
        ? ({ nativeEvent: { layerX: x, layerY: y } }) =>
            prop_onRightClick({
                point: { x: (x / pct) | 0, y: (y / pct) | 0 },
            })
        : undefined;

    const _onImageClick = ({ nativeEvent: { layerX: x, layerY: y } }) => {
        [x, y] = txViewport2DataCoords([x, y]);
        prop_onClick({
            point: { x, y },
        });
    }

    const svgViewport = useMemo(() => [0 / pct, 0 / pct, width / pct, height / pct], [width, height]);
    const [panning, setPanning] = useState();

    const {
        center, zoom,
        onWheel,
        onImageMouseDown,
        viewport2DataCoords,
        data2ViewportCoords,
        dispatch: panZoomDispatch,
    } = usePanZoom({
        ...props,
        width,
        height,
        pct,
        _onImageClick,
        onPanStart: () => setPanning(true),
        onPanStop: () => setPanning(false),
    });

    function txViewport2DataCoords(pt) {
        return viewport2DataCoords(pt, {toInt: true});
    }

    const onImageMouseMove = useMemo(() => onMouseMove ? ({ nativeEvent: { layerX: x, layerY: y } }) => {
        [x, y] = txViewport2DataCoords([x, y])
        onMouseMove({ x, y });
    } : undefined, [onMouseMove]);

    const [overlays, setOverlays] = useState();
    const setOverlay = useCallback((axis, overlayId, node) => setOverlays(overlays => {
        const newOverlays = {
            ...overlays,
            [axis]: {
                ...overlays?.[axis],
                [overlayId]: node
            }
        };

        if (!node) {
            delete newOverlays[axis][overlayId];
        }

        return newOverlays;
    }), [setOverlays]);

    const contextValue = useMemo(() => ({
        width, height,
        element: ref.current,
        dataWidth: width / pct, dataHeight: height / pct,
        pct, zoom, center, svgViewport,
        overlays, setOverlay,
        viewport2DataCoords, data2ViewportCoords,
        moveToCoords: coordCenter => panZoomDispatch.setCoordCenter(coordCenter)
    }), [
        width, height, pct, zoom, center, svgViewport,
        overlays, setOverlay,
        panZoomDispatch
    ]);


    useImperativeHandle(fref, () => contextValue, [contextValue]);


    return {
        ref,
        height,
        panning,
        onWheel,
        onImageMouseMove,
        onImageMouseDown,
        onRightClick,
        contextValue,
    };
};


export default React.forwardRef(SVGDrawer);
