import { useMemo, useRef } from "react";
import JsonSchemaTreeNode from "./JsonSchemaTreeNode";
import JsonSchemaTreeEditorActionDispatcher from "./JsonSchemaTreeEditorActionDispatcher";


function JsonSchemaTreeEditor({ schema, hideRoot, readonly, onChange }){
    const currentSchemaRef = useRef();
    currentSchemaRef.current = schema || {};
    const dispatchAction = useMemo(() => new JsonSchemaTreeEditorActionDispatcher(currentSchemaRef, onChange), [currentSchemaRef, onChange]);

    return (<div className='json-schema-tree-editor'>
        <JsonSchemaTreeNode hideRoot schema={schema} readonly={readonly} dispatchAction={dispatchAction} />
    </div>)
}


export default JsonSchemaTreeEditor;