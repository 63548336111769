import React, { useMemo, useState } from "react";
import ObjectEditorField from "../_formEditor/ObjectEditorField";
import StringField from "../_formEditor/ObjectEditorField/StringField";
import TestNotificationModal from "./TestNotificationModal";
import { FIELD_MAP } from "./fields";


function ObjectWorkflowEditor({
  setPath,
  object,
  state,
  onChange: propOnChange,
  forms,
  workflows,
  onDelete,
  onDuplicate
}) {
  const { roles, steps } = state;

  const [testNofitication, setTestNofitication] = useState();

  const scope = useMemo(
    () => ({
      steps: Object.entries(steps).map((obj, idx) => ({
        value: obj[0],
        label: obj[1].title
      })),
      pages: (
        forms && Object.keys(forms).length
          ? Object.entries(forms).map((obj, idx) => ({
              value: obj[1].page,
              label: obj[1].name
            }))
          : {}
      ),
      subWorkflows: (
        workflows && Object.keys(workflows).length
          ? Object.entries(workflows).map((obj, idx) => ({
              value: obj[1].name,
              label: obj[1].name
            }))
          : {}
      ),
      setTestNofitication
    }),
    [steps, forms, workflows]
  );

  FIELD_MAP.map(entry => {
    if (entry.property === "role") {
      entry.options = roles.map(role => ({ value: role, label: role }));
    }
    return entry;
  });

  function onChange(prop, value) {
    const newObject = { ...object };
    newObject[prop.property] = value;
    propOnChange(newObject);
  }

  return (
    <div className="container">
      {" "}
      <div className="row _formEditor">
        <div className="col-lg-12">
          <StringField
            label="Name"
            property="name"
            value={object.name}
            onChange={value => setPath(value)}
          />
        </div>
        {FIELD_MAP.map((prop, idx) => {
          return (
            <ObjectEditorField
              key={idx}
              id={idx}
              path={prop.property}
              scope={scope}
              fieldProps={prop}
              object={object}
              value={object ? object[prop.property] : null}
              onChange={value => onChange(prop, value)}
            />
          );
        })}
      </div>
      {testNofitication ? <TestNotificationModal
        onClose={() => setTestNofitication()}
        {...testNofitication}
      /> : null}
    </div>
  );
}

export default ObjectWorkflowEditor;
