import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { FormGroup } from "reactstrap";
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";

export default function DurationInput({
    id,
    schema,
    uiSchema,
    value,
    placeholder,
    required,
    disabled,
    readonly,
    autofocus,
    onChange: onChangeForm,
    onKeyChange,
    onBlur,
    onFocus,
    idSchema: { $id },
    schema: { title },
}) {
    const [current, setCurrent] = useState(value);
    useDebouncedEffect(() => {
        if (current !== value) {
            if(current && current.length === 5){
                onChangeForm(convertToMs())
            }
        }
    }, [current], 450);
    useEffect(() => setCurrent(value), [value]);
    const startWithTwo = current?.[0] === '2'

    const convertToMs = (() => {
        const [hours, minutes] = current.split(":").map(value => {
            if(value.includes("_")){
                return NaN
            }
            else{
                return parseInt(value, 10);
            }
        });
        const hoursToMs = hours * 60 * 60 * 1000;
        const minutesToMs = minutes * 60 * 1000;
        const totalMs = hoursToMs + minutesToMs;
        return totalMs;
    })

    const mask = {
        'H' : "[0-2]",
        'h': startWithTwo ? '[0-3]' : '[0-9]',
        'M': "[0-5]",
        'm': "[0-9]"
    }

    return (
        <FormGroup>
            {title.trim() ? (<label className="control-label" htmlFor={$id}>
                {title}{required ? <span className="required">*</span> : null}
            </label>) : null}
            <InputMask
                mask='Hh:Mm'
                formatChars={mask}
                maskChar='_'
                disabled={disabled}
                className="form-control"
                required={required}
                onChange={(e) => setCurrent(e.target.value)}
                readOnly={readonly}
                autoFocus={autofocus}
                id={id}
            />
        </FormGroup>
    );
}