import React, { useMemo } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { t } from "@lingui/macro";
import FormApi from "../../../api/FormApi";
import WorkflowApi from "../../../api/WorkflowApi";
import ObjectWorkflowEditor from "../../../components/_workflowEditor/ObjectWorkflowEditor";
import useResourceLoader from "../../../util/useResourceLoader";
import getStepType from "./stepTypes";

function WorkflowObjectSidepanel({
  dispatchAction,
  objectOnChange,
  selectedObject,
  setPathForSelectedObject,
  state
}) {
  const [forms, loadingForms, errorLoadingForms] = useResourceLoader(
    () => FormApi.getForms({ ignoreCache: true }),
    [],
    []
  );

  const [
    workflowResponse,
    loadingWorkflows,
    errorLoadingWorkflows
  ] = useResourceLoader(
    () => WorkflowApi.list({ offset: 0, size: 10 ** 6 }),
    [],
    []
  );

  const workflows = workflowResponse.items;
  const selection = state?.selection;
  const selectedStep = (state?.steps ?? {})[state?.selection];
  const sortedSteps = useMemo(() => {
    const stepValues = Object.values(state?.steps || {});
    stepValues.sort((a, b) => a?.title > b?.title ? 1 : (a?.title < b?.title ? -1 : 0))
    return stepValues
  }, [state.steps]);

  return <>
    <UncontrolledDropdown>
        <DropdownToggle caret style={{width: "100%", maxWidth: "auto", textAlign: "left"}}>
            <i className={getStepType(selectedStep?.type)?.icon ?? ""} />
            {" "}
            {selectedStep?.title ?? t`Select a workflow step`}
        </DropdownToggle>
        <DropdownMenu container="body" style={{maxHeight: "25vh", overflow: 'auto'}}>{sortedSteps.map(({name, type, title}) => (
          <DropdownItem key={name} onClick={() => dispatchAction.setSelection({selection: name})}>
            <i className={getStepType(type)?.icon ?? ""} />
            {" "}
            {title}
          </DropdownItem>
        ))}</DropdownMenu>
    </UncontrolledDropdown>
    {selectedObject ? (
      <ObjectWorkflowEditor
        key={selection}
        onChange={objectOnChange}
        state={state}
        forms={forms}
        workflows={workflows}
        setPath={setPathForSelectedObject}
        object={selectedObject}
        onDelete={dispatchAction.removeStep}
        onDuplicate={dispatchAction.duplicateStep}
      />
    ) : null}
  </>;
}

const STEP_TYPE_ICONS = {};

export default WorkflowObjectSidepanel;
