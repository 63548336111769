import { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import FormComponent from "../../../../components/FormComponent";
import parseFormDefinition from "../../../../util/parseFormDefinition";
import Switch from "../../../../components/Switch";

function ConfigPanel({ onAddFormId, state, dispatchAction }) {
    const {
        formIds,
        enabledProps,
        allPropertiesList
    } = state;

    const [expanded, setExpanded] = useState(true);
    return <div className="add-form-panel">
        <Button
            color={expanded ? "primary" : "secondary"}
            title="Add Forms"
            onClick={() => setExpanded(!expanded)}
        ><i className="fa fa-cog" /></Button>
        {expanded ? <div className="expand-section">
            <AddFormPanelForm onAddFormId={onAddFormId} shownFormIds={formIds} />
            {allPropertiesList ? (<PropertiesFilter allPropertiesList={allPropertiesList} enabledProps={enabledProps} dispatchAction={dispatchAction} />) : null}
        </div> : null}
    </div>
}


const AddFormPanelForm = FormComponent({
    ...parseFormDefinition({
        displayName: 'AddFormPanel',
        schemaProps: {
            title: "Add Form",
        },
        schema: {
            "objectSchemaId": {
                "title": "Object Schema",
                "type": "number", "ui:field": "LookupFormField",
                "lookup": {
                    "resource": "Entity Types",
                    "api": "api:manage/objectschemas",
                    "params": { "all": true },
                    "options": { "useCache": true },
                    "jnx": "data.items",
                    "id": "id", "label": "name"
                }
            },
            "formId": {
                "title": "Form",
                "ui:showIf": "objectSchemaId",
                "type": "string", "ui:field": "LookupFormField",
                "lookup": {
                    "resource": "Forms",
                    "api": "api:forms",
                    "params": {
                        "all": true,
                        "expr:entityType": "objectSchemaId",
                    },
                    "options": { "useCache": true },
                    "jnx": "items",
                    "id": "id", "label": "name",
                    "filter": "$not(id in $rootFormData.shownFormIds)"
                }
            },
        }
    }),
    withoutNavigation: true,
    parseProps: ({ onAddFormId, shownFormIds }) => ({ onAddFormId, shownFormIds }),
    beforeRenderHook: ({parsedProps: {shownFormIds}, formComponentRef, setFormDataValues}) => {
        const shownFormIds_sig = (shownFormIds || []).join(",");

        useEffect(() => {
            const form_shownFormIds = formComponentRef.current?.state?.shownFormIds || [];
            const form_shownFormIds_sig = (form_shownFormIds || []).join(",");
            const sigs_diff = shownFormIds_sig !== form_shownFormIds_sig;
    
            if (sigs_diff && shownFormIds_sig) {
                console.log("shownFormIds", shownFormIds);
                setFormDataValues({ shownFormIds });
            }
        }, [shownFormIds_sig, setFormDataValues]);
    },
    hideDataLoader: true,
    onSubmit: async ({ formData: {formId} }, {props: { onAddFormId }}) => {
        if (formId) {
            onAddFormId(formId);
        }
    },
    submitButtons: { submit: {
        text: "Add Form",
        className: "btn-primary",
        "ui:disableIf": "$isFalsy(formId)"
    } }
});

function PropertiesFilter({
    allPropertiesList = [],
    enabledProps = {},
    dispatchAction
}){
    const onChangeProp = useCallback((name, value) => {
        dispatchAction.setPropEnabled({ prop: name, enabled: !!value });
    }, [dispatchAction]);

    return <div className="section">
        <div className="title">Properties</div>
        <table><tbody>{(allPropertiesList || []).map((name) => <PropertyRow
            name={name}
            value={enabledProps[name] !== false}
            onChangeProp={onChangeProp}
        />)}</tbody></table>
    </div>;
}

function PropertyRow({ name, value, onChangeProp }){
    return (<tr key={name}>
        <td>{name}</td>
        <td><Switch value={value} onChange={({target: {checked}}) => {
            onChangeProp(name, !!checked);
        }} /></td>
    </tr>);
}

export default ConfigPanel;