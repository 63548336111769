import { t } from "@lingui/macro";
import Dashboard from "../../../components/Dashboard/index";


const ListRoles = Dashboard.HOC({
    slug: '?role=',
    schema: {
        "title": t`Roles`,
        "type": "role",
        "elements": [{
            "title": t`Roles`,
            "type": "worktray",
            ":className": "col-lg-12",
            "resource": "manage/roles",
            "tableActions": [{
                "label": t`Create Role`,
                "route": "${dashboard.slug}new",
            }],
            "filters": [
                { "title": t`Name`, "key": "name", "type": "string" },
            ],
            "columns": [
                { "title": t`Name`, "sortkey": "name", "map:field": "name" },
                { "title": t`Dashboards`, "sortkey": "name", "map:field": {"expr": "dashboardList ~> $join(',\n ')"} },
                { "title": t`Actions`, "actions": [{
                    "label": t`Edit`,
                    "route": "${dashboard.slug}$id",
                } ] },
            ]
        }]
    }
});


export default ListRoles;