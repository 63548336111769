import React from "react";

import SidebarComponents from './SidebarComponents';
import UWEFormComponent from "./UWEFormComponent";
import { UWEFormContextProvider } from "../context/UWEFormContext";


function UWEEntityUWEFormComponent({
    slug,
    form,
    currentEntity,
}) {


    return (
        <UWEFormContextProvider>
            <SidebarComponents currentEntity={currentEntity} title={form?.title} onComponents={form?.sidebarComponents}>
                <UWEFormComponent currentEntity={currentEntity} form={form} slug={slug} />
            </SidebarComponents>
        </UWEFormContextProvider>
    );
}


export default UWEEntityUWEFormComponent;