import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

export const ButtonBarContext = React.createContext(undefined);

export function ButtonBar({ className }) {
    const ctx = useContext(ButtonBarContext);
    return ctx?.groups?.length ? <div className={`button-bar ${className || ''}`}>{ctx.groups.map((group, idx) => (
        <div key={idx} className={`button-group align-${group.align}`}>{group.buttons?.map((button, idx) => (<React.Fragment key={idx}>{
            button || <div className="w-2" />
        }</React.Fragment>))}</div>
    ))}</div> : null;
}

export function useNewButtonBarContext() {
    const [groups, setGroups] = useState();

    const addGroup = useCallback((group) => {
        setGroups(groups => {
            const index = groups?.findIndex(g => g.name === group.name) ?? -1;
            return ([
                ...(groups ?? []).slice(0, index - 1),
                group,
                ...(groups ?? []).slice(index + 1),
            ]).sort((a, b) => (a.order ?? 100) - (b.order ?? 100))
        });
    }, []);

    const removeGroup = useCallback((group) => {
        setGroups(groups => groups?.filter(g => g.name !== group.name));
    }, []);

    const value = useMemo(() => ({
        addGroup,
        removeGroup,
        groups: groups ?? []
    }), [groups, addGroup, removeGroup])

    return value;
}


export function useButtonGroup(context, groupFn, deps) {
    const contextRef = useRef(context);
    contextRef.current = context;
    const group = useMemo(groupFn, [...deps]);

    useEffect(() => {
        if (!group) return;
        contextRef.current.addGroup(group);
        return () => {
            contextRef.current.removeGroup(group);
        }
    }, [contextRef, group]);

    return group;
}
