import React, { useEffect, useContext, useMemo } from 'react';
import UWEEntityApi from "../../api/UWEEntityApi";
import RequestReloadContext from '../../context/RequestReloadContext';
import usePolledResource from '../../util/usePolledResource';
import Loader from './Loader';


const WaitForExternalProcess = ({ currentEntity, type, page }) => {
    const rrc = useContext(RequestReloadContext) || window.location;
    const currentSteps = useMemo(() => currentEntity.status.filter(x => x.type === type), [currentEntity]);
    const [entityStatus] = usePolledResource({
        resourceFn: () => UWEEntityApi.getUWEEntityStatus({ entityId: currentEntity.id }),
        dependencies: [currentEntity.id],
        interval: 5000,
        initialValue: {}
    });

    const waitingForSteps = useMemo(() => (
        entityStatus.status ? (
            currentSteps.filter(x => entityStatus.status.indexOf(x.step) >= 0)
        ) : currentSteps
    ), [currentSteps, entityStatus]);

    useEffect(() => {
        console.log("waitingForSteps", waitingForSteps);
        // if (status === WaitingForDocumentState.AVAILABLE) {
        //     rrc.reload();
        // }
    }, [waitingForSteps, rrc]);

    return (
        <div className="d-flex justify-content-center flex-column text-center">
            <Loader big />
            <h4>
                Esperando por:<br />
                {waitingForSteps.map(({name}, idx) => <div key={idx}>{name}</div>)}
            </h4>
        </div>
    )
}

export default WaitForExternalProcess;