import jsonata from "jsonata";
import { useEffect, useMemo } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { concatenatePaths, getObject, mapObject } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import applyFormatting, { useAppliedFormatter } from "../../util/applyFormatting";
import { useJnx } from "../../util/jnx";
import useOptionsLookup from "./hooks/useOptionsLookup";
import getPathFromId from "../../util/getPathFromId";


function ComputedField(props) {
    const {
        formContext,
        name,
        schema,
        onChange,
        idSchema: { $id },
        formData: formDataFieldValue,
    } = props;

    const {
        title,
        references,
        format,
        jsonata: jsonataComputation,
        updateIf: updateIfExpr,
        computation,
        scope: schemaScope = '',
        onUpdate,
        lookup,
        hidden
    } = schema;

    const {
        sideChannel,
        bindings: fcBindings,
        setFormDataValues,
    } = formContext;

    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}, {}];

    const {
        options: lookupOptions,
        loadingOptions,
        errorLoadingOptions
    } = useOptionsLookup({
        lookup,
        rootFormData,
    });

    const bindings = useMemo(() => ({
        ...fcBindings,
        formObject,
        formContext
    }), [fcBindings, formObject, formContext]);

    const path = useMemo(() => getPathFromId($id), [$id]);
    const valueJnx = useJnx(jsonataComputation);
    const updateIfJnx = useJnx(updateIfExpr);
    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
        log: (...args) => console.log("jsonata console log\n", ...args)
    }), [setFormDataValues]);
    const onUpdateJnx = useJnx(onUpdate, { functionBinds });

    useEffect(() => {
        if (valueJnx) {
            try {
                const context = { ...rootFormData, lookupOptions };
                const refValue = valueJnx.eval(context, path, {bindings, lastValue: formDataFieldValue});
                if (refValue !== formDataFieldValue) {
                    // console.log(`Update ComputedField ${name} to ${refValue}`);
                    onChange(refValue);
                    if (onUpdateJnx) setTimeout(() => {
                        onUpdateJnx.evalAsync(rootFormData, path, { ...bindings, value: refValue });
                    }, 100);
                }
            } catch (e) {
                console.log("Computed Field error");
                console.error(e);
            }
        }
    }, [valueJnx, updateIfJnx, rootFormData, path, formDataFieldValue, lookupOptions]);

    const text = useAppliedFormatter(formDataFieldValue, format);

    return hidden ? null : (<FormGroup disabled>
        {title !== " " ? <label className="control-label" htmlFor="root_preferredBranchId">
            {title}
        </label> : null}
        <Input
            disabled
            type="text"
            className="custom"
            value={text || ""}
            readOnly
        />
    </FormGroup>);
}


export default ComputedField;