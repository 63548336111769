import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Arrow from "../../../components/SVGDrawer/shapes/Arrow";


function WorkflowArrow({
    from,
    to,
    text,
}){
    const [loopingTest, setLoopingTest] = useState(false);
    const [loopingOffsetX, setLoopingOffsetX] = useState(20);
    const [loopingOffsetY, setLoopingOffsetY] = useState(160);
    const endpoints = useMemo(() => {
        if (!from || !to) return null;
        const w1 = from.size?.width | 0;
        const h1 = from.size?.height | 0;
        const w2 = to.size?.width | 0;
        const h2 = to.size?.height | 0;
    
        const lirx = lir(from.x, w1, to.x, w2);
        const liry = lir(from.y, h1, to.y, h2);

        let fromLirxAdjusted = 1;
        let fromLiryAdjusted = 0;
        let toLiryAdjusted = 0;
        let toLirxAdjusted = 1;
       
        if (text === "sendBack"){
            if (lirx === 0 && liry === -1){
                fromLiryAdjusted = 0;
                fromLirxAdjusted = 0;
                toLiryAdjusted = -1;
            }
            else if (lirx === 0 && liry === 1){
                fromLiryAdjusted = 1;
                toLirxAdjusted = -1;
            }
            else if (lirx === -1 && liry === 1){
                fromLirxAdjusted = 0;
            }
            else if (lirx === -1 && liry === 0 ){
                fromLirxAdjusted = 0;
                toLirxAdjusted = -1;
            }
            else if (lirx === -1 && liry === -1){
                fromLiryAdjusted = 1;
                fromLirxAdjusted = 0;
                toLiryAdjusted = -1;
            }
            else if (lirx === 1 && liry === -1){
                fromLirxAdjusted = 0;
            }
            else if (lirx === 1 && liry === 1){
                toLiryAdjusted = 0;
                toLirxAdjusted = 0;
            }
        }

        return [
            [
                from.type === "branch" && lirx === 0 ? (
                    liry === 1 ? from.x + w1 * (1 - lirx) / 2 + 10 : from.x + w1 * (1 - lirx) / 2 + 10) : (
                        liry === 0 && from.type === "branch" ? from.x + w1 * (1 - lirx) / 2 + 20 : (
                            text === "sendBack" ? from.x + w1 * (1 - fromLirxAdjusted) : (loopingTest ? from.x + w1 * (1 - lirx) : from.x + w1 * (1 - lirx) / 2))),
                from.type === "branch" && lirx === 0 ? (
                    liry === 1 ? from.y + h1 - 75 : from.y + h1 * (1 - liry)) : (
                        text === "sendBack" ?  from.y + h1 * (1 - fromLiryAdjusted)  : (loopingTest ? from.y + h1 * (1 - liry) / 4 :from.y + h1 * (1 - liry) / 2))
            ], [
                to.type === "branch" && lirx === 0 ? (
                    liry === 1 ? to.x + w2 * (1 + lirx) / 2 + 10 : to.x + w2 * (1 + lirx) / 2 + 10) : (
                        text === "sendBack" ? to.x + w2 * (1 + toLirxAdjusted) / 2 : (loopingTest ? to.x + w2 * (1 + lirx) / 2: to.x + w2 * (1 + lirx) / 2)),
                to.type === "branch" && lirx === 0 ? (
                    liry === 1 ? to.y + h2 + 45: to.y + h2 - 80) : (
                        text === "sendBack" ? to.y + h2 * (1 + toLiryAdjusted) : (loopingTest ? to.y * (1 + liry) : to.y + h2 * (1 + liry) / 2)),
            ]
        ];
    }, [
        from?.x, from?.y, from?.size,
        to?.x, to?.y, to?.size
    ])

    useEffect(()=>{
        if(text === "guardar" && (to === from)){
            setLoopingTest(true)
        }
        else{
            setLoopingOffsetX(0);
            setLoopingOffsetY(0);
        }
    }, [
        to, from, text
    ])

    return endpoints ? <>
        <Arrow path={endpoints} text={text} loopingTest={loopingTest} fromSize={from.size} shrinkFrom={3} shrinkTo={5} minLength={5} />
        {text ? <TextBox
            x={((endpoints[0][0] + endpoints[1][0] + loopingOffsetX) / 2) | 0}
            y={((endpoints[0][1] + endpoints[1][1] - loopingOffsetY) / 2) | 0}
            alignmentBaseline="middle"
            textAnchor="middle"
            fill="#ffffff"
            text={text} /> : null}
    </> : null;
}


function TextBox({
    x, y, alignmentBaseline, textAnchor,
    fill,
    text: txt
}) {
    const ref = useRef();
    const [rect, setRect] = useState();

    useLayoutEffect(() => {
        if (!ref.current) return;
        const r = ref.current.getBoundingClientRect();
        const pr = ref.current.parentNode.getBoundingClientRect();
        const {x, y, width, height} = r;
        const {x: px, y: py} = pr;
        setRect([
            (x - px - width / 2) | 0, (y - py - height / 2) | 0,
            width | 0, height | 0,
            r, pr
        ]);
    }, [txt, x, y, alignmentBaseline, textAnchor]);

    return txt ? <g transform={`translate(${x}, ${y})`}>
        {rect ? <rect x={rect[0]} y={rect[1]} width={rect[2]} height={rect[3]} fill={fill} /> : null}
        <text ref={ref} x={0} y={0} alignmentBaseline={alignmentBaseline} textAnchor={textAnchor}>{txt}</text>
    </g> : null
}


/** returns wether a given line segment is to the left, intersects or to the right of another given line segment. 
 *  @returns number -1, 0, 1 indicating that the line segment is to the left, intersects or to the right of the other line segment, respectively.
 */
function lir(x1, w1, x2, w2) {
    const x12 = x1 + w1;
    const x22 = x2 + w2;
    return x12 < x2 ? -1 : (x22 < x1 ? 1 : 0);
}

export default WorkflowArrow;