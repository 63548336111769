import { useMemo } from "react";
import { useLocation } from "react-router";
import { interpolate } from "./mapObject";
import { embedJnx, useJnx } from "./jnx";


export function interpolateRoute(route, scope) {
    return route ? interpolate(`${route}`, scope) : null;
}

export function concatPath(r1, ...r2s){
    return r2s.reduce((r1, r2) => {
        r2 = `${r2}`;
        if (r2.startsWith('/')) {
            return r2;
        } else {
            const delim = r1.endsWith('/') ? '' : '/';
            return `${r1}${delim}${r2}`;
        }
    }, `${r1}`);
}

function buildRouteExpr (route) {
    return embedJnx(route, x => `(
        $r := (${x});
        $isTruthy($r) ? $concatPath($pathname, $r) : null
    )`);
}

export function useRouteInterpolation(route, routeExpr, scope){
    const { pathname } = useLocation();
    const expr = useMemo(() => buildRouteExpr(routeExpr ?? '$interpolateRoute($route, $scope)'), [routeExpr]);
    const jnx = useJnx(expr, {
        functionBinds: {
            interpolateRoute,
            concatPath
        }
    });

    return useMemo(() => {
        return jnx.eval('', '', {
            route,
            pathname,
            scope,
            ...scope
        });
    }, [route, jnx, scope, pathname]);
}