import { useMemo } from "react";
import Switch from "../../../../components/Switch";

function ValueCell({
    unrolledForms, formId, field, fieldProp
}){
    const value = useMemo(() => unrolledForms?.[formId]?.[field]?.[fieldProp], [unrolledForms, formId, field, fieldProp]);
    const valueComponent = useMemo(() => {
        if (fieldProp === "type") {
            return <>
                <i className={`icon icon-json-${value}`}/>
                {value}
            </>
        }

        switch (typeof value){
            case "string": return value === "" ? <span className="text-info">(empty string)</span> : value;
            case "boolean": return <Switch value={value} />;
            default: return JSON.stringify(value);
        }
    }, [fieldProp, value])

    return (<td key={formId} className={`value-cell ${(value !== undefined) ? "" : "is-empty"}`}>{valueComponent}</td>)
}


export default ValueCell;