import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';
import checkDocumentResponse, { STATUS } from './checkDocumentResponse';
import IntegrationsApi from "./IntegrationsApi";


const UWEEntityApi = {

    async getWorkTray({ resource, offset, size, sortBy, ...filters }) {
        const result = await checkErrors(await fetchApi.api.get(resource, { query: { offset, size, sortBy, ...filters } }));
        return result.data ? result.data : result;
    },

    async getUWEEntity({ entityId }) {
        const entity = await checkErrors(
            await fetchApi.api.get(`uwe-entities/${entityId}`)
        )

        return entity;
    },

    async getUWEEntityStatus({ entityId }) {
        return (await checkErrors(
            await fetchApi.api.get(`uwe-entities/${entityId}/status`)
        )).data;
    },

    async performActionOnUWEEntity({ id, action }) {
        return checkErrors(
            await fetchApi.api.post(`uwe-entities/${id}/perform-action/${action}`)
        )
    },

    async getUWEEntityHistory({ id }) {
        return (await checkErrors(
            await fetchApi.api.get(`uwe-entities/${id}/history`)
        )).data;
    },

    async getWorkflowInfoForSlugForAnonymous({ slug, tenant } = {}) {
        return (await checkErrors(
            await fetchApi.api.get(`anon/${tenant}/workflows/${slug}/info`)
        )).data;
    },

    async getWorkflowInfoForSlug({ slug } = {}) {
        return (await checkErrors(
            await fetchApi.api.get(`workflows/info/${slug}`)
        )).data;
    },
    async getWorkflowInfoForId({ id } = {}) {
        return (await checkErrors(
            await fetchApi.api.get(`workflows/info/workflow:${id}`)
        )).data;
    },

    async getWorkflowStates({ workflowId, workflowName } = {}) {
        const query = { id: `workflow:${workflowName || workflowId}` };
        return (await checkErrors(
            await fetchApi.lookup.get(`workflow-states`, { query })
        )).data;
    },

    async getWorkflowStatesForEntity({ entityId } = {}) {
        const query = { id: `entity:${entityId}` };
        return (await checkErrors(
            await fetchApi.lookup.get(`workflow-states`, { query })
        )).data;
    },

    async getWorkflowStatusMap({ id } = {}) {
        const states = (await UWEEntityApi.getWorkflowStatesForEntity({ entityId: id })) || [];
        return states.reduce((_, { name, title }) => {
            (_[name] = title || name);
            return _;
        }, {});
    },

    async getUWEEntityLog({ id }) {
        return checkErrors(
            await fetchApi.api.get(`uwe-entities/${id}/log`)
        )
    },

    async getUWEEntityProgressSteps({ entityId, fromParent }) {
        const query = {
            fromParent: fromParent ? "1" : undefined
        };

        return checkErrors(
            await fetchApi.api.get(`uwe-entities/${entityId}/progress-steps`, {query})
        );
    },

    async teleportUWEEntityToLogEntry({ id, stepIdx }) {
        return checkErrors(
            await fetchApi.api.post(`uwe-entities/${id}/log/teleport`, { data: { index: stepIdx } })
        )
    },

    async testDocumentExpression({ id, options }) {
        return checkErrors(
            await fetchApi.api.put(`uwe-entities/${id}/test/document-expression`, { data: options })
        );
    },

    async testInternalNotificationMessage({ id, object }) {
        return checkErrors(
            await fetchApi.api.put(`uwe-entities/${id}/test/internal-notification`, { data: object })
        );
    },

    async previewMetadata({ id, metadataObjectMap }) {
        return (
        await checkErrors(await fetchApi.api.post(`uwe-entities/${id}/preview-metadata`, { data: metadataObjectMap }))
        ).data;
    },

    async getUWEEntityAssignments({ id }) {
        return checkErrors(await fetchApi.api.get(`uwe-entities/${id}/assignments`));
    },

    async setUWEEntityAssignment({ id, roleId, userId }) {
        return checkErrors(await fetchApi.api.post(`uwe-entities/${id}/assign`, {
            data: { roleId, userId }
        }));
    },

    async setEntityWorkflowStep({ id, step }) {
        return checkErrors(await fetchApi.api.post(`uwe-entities/${id}/set-step`, {
            data: { step }
        }));
    },

    async createUWEEntity({ page, formData }) {
        return checkErrors(await fetchApi.api.post(`uwe-entities/`, { data: { ...formData, page } }));
    },

    async updateUWEEntity({ id, page, formData }) {
        return checkErrors(await fetchApi.api.put(`uwe-entities/${id}/${page}`, { data: formData }));
    },

    async createUWEEntityAsAnonymous({ payload: { page, formData }, slug, tenant }) {
        return checkErrors(await fetchApi.api.post(`anon/${tenant}/workflows/${slug}/uwe-entities/new`, { data: { ...formData, page } }));
    },

    async updateUWEEntityAsAnonymous({ payload: { id, page, formData }, slug, tenant }) {
        return checkErrors(await fetchApi.api.put(`anon/${tenant}/workflow/${slug}/uwe-entities/${id}/${page}`, { data: formData }));
    },

    async addProducts({ id, productIds }) {
        return checkErrors(
            await fetchApi.api.post(`uwe-entities/${id}/products`, { data: { productIds } })
        );
    },

    async awaitDocuments({ id, page }) {
        return checkErrors(
            await fetchApi.api.put(`uwe-entities/${id}/await-documents`, {
                data: {
                    page
                }
            })
        );
    },

    async createUWEEntityNote({ entityId, note }) {
        return checkErrors(await fetchApi.api.post(`uwe-entities/${entityId}/notes`, {
            data: note, json: true
        }));
    },

    async getUWEEntityNotes({ entityId }) {
        return checkErrors(await fetchApi.api.get(`uwe-entities/${entityId}/notes`));
    },

    async getRoleAssignments({ id }) {
        return checkErrors(await fetchApi.api.get(`uwe-entities/${id}/role-assignments`));
    },

    async getUWEEntityZipDownloadUrl({ id, filter }) {
        return (await checkErrors(await fetchApi.api.get(`uwe-entities/${id}/download-documents`, { query: { filter } }))).data;
    },
    async getWorkflowRoles({ id }) {
        return checkErrors(await fetchApi.api.get(`uwe-entities/${id}/workflow-roles`));
    },
    async getProgressByOrganization({ id }) {
        return checkErrors(await fetchApi.api.get(`uwe-entities/getProgressByOrganization/${id}`));
    },

    async generatePreviewFor({ id, data }) {
        return (await checkErrors(
            await fetchApi.api.post(`uwe-entities/${id || 0}/form-preview`, {
                data, json: true
            })
        )).data;
    },

    async verifyPreviewGeneration({ referenceId }) {
        const response = checkDocumentResponse((await checkErrors(
            await fetchApi.api.get(`uwe-entities/form-preview/verify/${referenceId}`)
        )).data);

        if (response.status === STATUS.NOTFOUND || response.status === STATUS.ERROR) {
            throw new Error("Error buscando documento.");
        }

        return response;
    },

    async submissionFromEmail({ token }) {
        return (await checkErrors(
            await fetchApi.api.get(`uwe-entities/submissionFromEmail/${token}`)
        )).data;
    },

}


export default UWEEntityApi;
