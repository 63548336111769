import TypesSet from "../../../util/TypesSet";

const shapePaths = new TypesSet({
    circle: ({x, y, pad, width, height}) => [
        ["circle", {
            "cx": (x - pad) + width/2 + pad ,
            "cy": (y - pad) + height/2 + pad,
            "r": width/2 + pad,
            "width": width + 2 * pad,
            "height": height + 2 * pad,
        }]
    ],
    rectangle2: ({x, y, pad, width, height}) => [
        ["rect", {
            "x": x - pad - width/8,
            "y": y - pad,
            "width": width + 2 * pad + (width/8)*2,
            "height": height + 2 * pad,
        }]
    ],
    diamond: ({x, y, pad, width, height}) => [
        ["path", {
            "d": "M ".concat(x - pad*2).concat(" ").concat((y + (height/2)))
                .concat(" L ").concat(x + width/2 + pad*3.25).concat(" ").concat(y - (height) - pad)
                .concat(" L ").concat(x + width + pad*9).concat(" ").concat(y + height/2)
                .concat(" L ").concat(x + width/2 + pad*3.25).concat(" ").concat(y + height*2 + pad)
                .concat(" Z")
        }]
    ],
    document: ({x, y, pad, width, height}) => [
        ["path", {
            "d": "M ".concat(x - pad).concat(" ").concat((y - pad))
                .concat(" L ").concat(x + width + pad).concat(" ").concat(y - pad)
                .concat(" L ").concat(x + width + pad).concat(" ").concat(y + height + pad*2)
                .concat(" Q ").concat(x + width*0.75 + pad).concat(" ").concat(y + height*0.6 + pad).concat(" ").concat(x + width/2 + pad).concat(" ").concat(y + height + pad)
                .concat(" Q ").concat(x + width/4 + pad).concat(" ").concat(y + height*1.4 + pad*1.5).concat(" ").concat(x - pad).concat(" ").concat(y + height + pad)
                .concat(" Z")
        }]
    ],
    hexagon: ({x, y, pad, width, height})  => [
        ["path", {
            "d": "M ".concat(x - pad).concat(" ").concat((y - pad))
                .concat(" L ").concat(x + width + width*0.05 + pad).concat(" ").concat(y - pad)
                .concat(" L ").concat(x + width + width*0.25 + pad).concat(" ").concat((y + height/2))
                .concat(" L ").concat(x + width + width*0.05 + pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x - pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x + width*0.75 - width - pad).concat(" ").concat((y + height/2))
                .concat(" Z")
        }]
    ],
    message: ({x, y, pad, width, height}) => [
        ["path", {
            "d": "M ".concat(x - pad).concat(" ").concat((y - pad))
                .concat(" L ").concat(x + width + pad).concat(" ").concat(y - pad)
                .concat(" L ").concat(x + width + pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x + width - width*0.4 + pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x + width - width*0.6 - pad).concat(" ").concat(y + height + height*0.4 + 2*pad)
                .concat(" L ").concat(x + width - width*0.6 - pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x - pad).concat(" ").concat(y + height + pad)
                .concat(" Z")
        }]
    ],
    page: ({x, y, pad, width, height}) => [
        ["path", {
            "d": "M ".concat(x - pad + 10).concat(" ").concat((y - pad))
                .concat(" L ").concat(x + width + width*0.05 + pad).concat(" ").concat(y - pad)
                .concat(" L ").concat(x + width + width*0.05 + pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x + width*0.9 - width - pad).concat(" ").concat(y + height + pad)
                .concat(" L ").concat(x + width*0.9 - width - pad).concat(" ").concat(y + height - height*.6 - pad)
                .concat(" Z")
        }]
    ],
    default: ({x, y, pad, width, height}) => [
        ["rect", {
            "x": x - pad,
            "y": y - pad,
            "width": width + 2 * pad,
            "height": height + 2 * pad,
        }]
    ]
}, {
    rectangle: "default"
})


export default shapePaths