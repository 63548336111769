import SendEmail from "./SendEmail";
import FileField from "./FileField";
import Button from "./Button";
import UWEEntityZipDownloadField from "./UWEEntityZipDownloadField";
import BoolYesNoField from "./BoolYesNoField";
import IconCheckField from "./IconCheckField";
import CheckboxToggleButtonField from "./CheckboxToggleButtonField";
import HideObjectField from "./HideObjectField";
import RiskMatrixField from "./RiskMatrixField";
import RiskMatrixFieldBusiness from "./RiskMatrixFieldBusiness";
import SignatureField from "./SignatureField";
import CompositeInputField from "./CompositeInputField";
import ComputedField from "./ComputedField";
import LookupFormField from "./LookupFormField";
import LookupFormStaticField from "./LookupFormStaticField";
import LookupMultiSelectCheckboxesFormField from "./LookupMultiSelectCheckboxesFormField";
import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import TimerField from "./TimerField";
import SubformField from "./SubformField";
import MoneyInput from "./MoneyInput";
import CedulaInput from "./CedulaInput";
import StaticField from "./StaticField";
import SwitchField from "./SwitchField";
import ToggleObjectField from "./ToggleObjectField";
import ArrayField from "./ArrayField";
import Autocomplete from "./AutocompleteField";
import StaticTextBlurb from "./StaticTextBlurb";
import InteractiveDrawing from "./InteractiveDrawing";
import JnxField from "./JnxField";
import JsonField from "./JsonField";
import JsonSchemaField from "./JsonSchemaField";
import SelectImageField from "./SelectImageField";
import QRCode from "./QRCode";
import QrRead from "./QrRead";
import WorktrayEntitySelectField from "./WorktrayEntitySelectField";
import StaticIFrame from "./StaticIFrame";
import TimeInputField from "./TimeInputField";
import DurationInput from "./DurationInput";


const customFields = {
    SendEmail,
    FileField,
    Button,
    UWEEntityZipDownloadField,
    RiskMatrixField,
    RiskMatrixFieldBusiness,
    ComputedField,
    SignatureField,
    CompositeInputField,
    HideObjectField,
    BoolYesNoField,
    BoolYesNo: BoolYesNoField,
    IconCheckField,
    CheckboxToggleButtonField,
    LookupFormField,
    LookupFormStaticField,
    LookupMultiSelectCheckboxesFormField,
    TextInput,
    PhoneInput,
    TimerField,
    JnxField,
    JsonField,
    JsonSchemaField,
    SelectImageField,
    MoneyInput,
    SubformField,
    CedulaInput,
    StaticField,
    ArrayField,
    SwitchField,
    ToggleObjectField,
    WorktrayEntitySelectField,
    Autocomplete,
    StaticTextBlurb,
    InteractiveDrawing,
    QRCode,
    QrRead,
    StaticIFrame,
    TimeInputField,
    DurationInput
}


export default customFields;