import React, { useContext } from 'react';
import { useLingui } from '@lingui/react';
import { Container } from 'reactstrap';
import AppContext from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import NavMenu from '../NavMenu';
import Sidebar from '../Sidebar';


function InternalLayout({
  children,
  tabs, activeTab, setActiveTab,
}) {
  const {user} = useContext(AuthContext);
  const {sidebarExpanded} = useContext(AppContext);
  const versionTag = window.APP_VERSION;
  const {i18n: { _locale: locale } = {}} = useLingui() ?? {};

  return (
    <div lang={locale} className={`app-layout ${sidebarExpanded ? 'has-sidebar-tab-open' : ''}`}>
      <NavMenu />
      {user ? <Sidebar
        tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
      /> : null}
      <Container className="main-container" fluid>
        {children}
      </Container>
      {versionTag ? (<div className="version-tag">{versionTag}</div>) : null}
    </div>
  );
}


export default InternalLayout;