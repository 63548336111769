import React, { useContext, useImperativeHandle, useRef } from 'react';
import Context2D from "./Context";


function ScalableMovableSVG({
    className,
    onWheel,
    smoothTransitions,
    transitionTime=1,
    transitionEasing="ease",
    onMouseMove,
    onMouseDown,
    onRightClick,
    children,
}, ref) {
    const {
        width,
        height,
        center,
        zoom: propZoom,
        overlays
    } = useContext(Context2D);

    const zoom = propZoom || 1;
    const w2 = width / 2;
    const h2 = height / 2;
    const [cx, cy] = center || [w2, h2];
    let dx = w2 - cx * zoom;
    let dy = h2 - cy * zoom;

    if (!Number.isFinite(dx)) { dx = 0; }
    if (!Number.isFinite(dy)) { dy = 0; }

    const onContextMenu = onRightClick ? (event => {
        event.preventDefault();
        onRightClick(event); 
    }) : undefined;

    const style = smoothTransitions ? {transition: `transform ${transitionTime}s ${transitionEasing}`} : undefined

    return (<svg
        className={className}
        onContextMenu={onContextMenu}
        width={width | 0}
        height={height | 0}
        onWheel={onWheel}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
    >
        {overlays?.xBack ? (
            <g style={style} transform={`translate(0 ${dy}) scale(${zoom} ${zoom})`} >{Object.entries(overlays.xBack).map(([k, v]) => (
                <React.Fragment key={k}>{v}</React.Fragment>
            ))}</g>
        ) : null}
        {overlays?.yBack ? (
            <g style={style} transform={`translate(0 ${dy}) scale(${zoom} ${zoom})`} >{Object.entries(overlays.yBack).map(([k, v]) => (
                <React.Fragment key={k}>{v}</React.Fragment>
            ))}</g>
        ) : null}
        <g style={style} transform={`translate(${dx} ${dy}) scale(${zoom} ${zoom})`}>
            <g>{children}</g>
        </g>
        {overlays?.x ? (
            <g style={style} transform={`translate(${dx} 0) scale(${zoom} ${zoom})`} >{Object.entries(overlays.x).map(([k, v]) => (
                <React.Fragment key={k}>{v}</React.Fragment>
            ))}</g>
        ) : null}
        {overlays?.y ? (
            <g style={style} transform={`translate(0 ${dy}) scale(${zoom} ${zoom})`} >{Object.entries(overlays.y).map(([k, v]) => (
                <React.Fragment key={k}>{v}</React.Fragment>
            ))}</g>
        ) : null}
    </svg>);
}

export default ScalableMovableSVG;
