import React from "react";

import Shape from "../../../components/SVGDrawer/shapes/Shape";
import getStepType from "./stepTypes";


function WorkflowStep({ stepdef, onSetSize, onClick, onDrag, selected }) {
  const { title, x, y, name, size, type } = stepdef;
  const stepType = getStepType(type);
  const { shape, svgIcon: icon } = stepType;

  return (
    <Shape
      className="step"
      x={x}
      y={y}
      shape={shape}
      icon={icon}
      width={size?.width}
      height={size?.height}
      selected={selected}
      onClick={onClick}
      onDrag={onDrag}
      onSetSize={onSetSize}
      content={title}
    />
  );
}

export default WorkflowStep;
