import React from 'react';
import AppContext from './context/AppContext';
import routes from './routes';
import AuthVerification from './components/AuthVerification';
import UWEContext from './context/UWEContext';
import RouteSwitch from './components/RouteSwitch';


function App() {
  return (
    <AppContext.Component><UWEContext.Component>
      <AuthVerification />
      <RouteSwitch routes={routes} />
      </UWEContext.Component></AppContext.Component>
  );
}


export default App;
