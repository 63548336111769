import React, { useCallback, useContext, useMemo, useRef } from 'react';
import shapePaths from './shapePaths';
import Context2D from '../Context';
import useDragEffect from '../useDragEffect';


function SelectionShape({
    x=0,
    y=0,
    width=10,
    height=10,
    className='',
    onClick,
    draggable,
    onDrag: prop_onDrag,
    onDrop,
    shape
}){
    const [Shape, shapeProps] = useMemo(() => (
        shapePaths.call(shape, {x, y, width, height, pad: 3})[0]
    ), [shape, x, y, width, height]);

    const { viewport2DataCoords } = useContext(Context2D);
    const coordinateMap = useCallback(({x, y}) => {
        const [mx, my] = viewport2DataCoords([x, y], {toInt: true});
        return {x: mx, y: my};
    }, [viewport2DataCoords]);
    const dragDelta = useRef();
    const [onDragStart, onDrag] = useMemo(() => prop_onDrag ? [
        (idx, sx, sy) => { dragDelta.current = { x: x - sx, y:  y - sy}; },
        (idx, x, y) => prop_onDrag(idx, x + (dragDelta.current?.x || 0), y + (dragDelta.current?.y || 0)),
    ] : null, [prop_onDrag]);

    const {
        dragState,
        onMouseDownDelegate,
        onClickDelegate,
    } = useDragEffect({
        onDrag,
        onDragStart,
        onDrop,
        onClick,
        coordinateMap
    });

    return Shape ? <Shape
        className={`selection ${className} ${draggable ? 'draggable' : ''}  ${dragState ? 'is-dragging' : ''}`}
        onClick={onClickDelegate}
        onMouseDown={onMouseDownDelegate}
        {...shapeProps}
    /> : null;
}


export default SelectionShape;
