import { t } from "@lingui/macro";
import UWEEntityApi from "../../api/UWEEntityApi";

export const INTERVAL_UNITS = [
  { value: "Day", label: "Days" },
  { value: "Hour", label: "Hours" },
  { value: "Minute", label: "Minutes" }
];

export const FIELD_MAP = [
  {
    property: "title",
    label: t`Title`,
    type: "string"
  },
  {
    property: "initial",
    label: t`Is Initial`,
    type: "boolean",
    showIf: { expr: "type != 'end'" }
  },
  {
    property: "progressGroup",
    label: t`Progress Group`,
    type: "string",
    showIf: { expr: "$not(excludeFromProgress = true and type in ['form', 'subProcess', 'end'])" }
  },
  {
    property: "excludeFromProgress",
    label: t`Exclude From Progress Panel`,
    type: "boolean",
    showIf: { expr: "type in ['form', 'subProcess', 'end']" }
  },
  {
    property: "type",
    label: t`Type`,
    type: "select",
    options: [
      { value: "form", label: t`Form` },
      { value: "externalProcess", label: t`External Process` },
      { value: "calculation", label: t`Calculation` },
      { value: "branch", label: t`Decision` },
      { value: "documentGeneration", label: t`Document Generation` },
      { value: "notification", label: t`Notification` },
      { value: "emailForm", label: t`Email Form` },
      { value: "subWorkflow", label: t`Sub Workflow` },
      { value: "wait", label: t`Wait Step` },
      { value: "end", label: t`End` }
    ]
  },
  {
    property: "email",
    label: t`Email`,
    type: "object",
    properties: [
      {
        property: "recipient",
        label: t`Recipient`,
        type: "string"
      },
      // {
      //     property: "sender", label: t`Sender`, type: "string",
      // },
      {
        property: "emailFormName",
        label: t`Form Name`,
        type: "string",
        format: "string"
      },
      {
        property: "subject",
        label: t`Subject`,
        type: "string"
      },
      {
        property: "message",
        label: t`Message`,
        type: "string",
        format: "textarea"
      }
    ],
    showIf: { expr: "type = 'emailForm'" }
  },
  {
    property: "page",
    label: t`Page`,
    type: "select",
    optionsFromScope: "pages",
    options: true,
    rightButtons: [{
      icon: "fa fa-clipboard-list",
      tooltip: "View Form",
      onClick: ({ value }) => {
          const url = `/system-settings/forms/?form=${encodeURIComponent(`name-${value}`)}`;
          window.open(url, "_blank");
      }
    }],
    showIf: { expr: "type in ['form', 'subWorkflow', 'wait']" }
  },
  {
    property: "subWorkflow",
    label: t`Sub-Workflow`,
    type: "select",
    optionsFromScope: "subWorkflows",
    options: true,
    rightButtons: [{
      icon: "fa fa-network-wired",
      tooltip: "View Sub-Workflow",
      onClick: ({ value }) => {
          const url = `/system-settings/workflow/?workflow=${encodeURIComponent(`name-${value}`)}`;
          window.open(url, "_blank");
      }
    }],
    showIf: { expr: "type = 'subWorkflow'" }
  },
  {
    property: "data",
    label: t`Data`,
    type: "json",
    showIf: { expr: "type = 'subWorkflow'" }
  },
  {
    property: "role",
    label: t`Role`,
    type: "select",
    options: []
  },
  {
    property: "documentGeneration",
    label: t`Document`,
    type: "object",
    properties: [
      {
        property: "event",
        label: t`Document Event`,
        default: "Document",
        type: "string"
      }, {
        property: "documentName",
        label: t`Document Name`,
        type: "string"
      }, {
        property: "prefix",
        label: t`Prefix`,
        type: "string"
      }, {
        property: "dontSave",
        label: t`Dont Save`,
        type: "boolean"
      }, {
        property: "includeNotification",
        label: t`Includes Notification`,
        type: "boolean"
      }, {
      }, {
        property: "awaitGeneration",
        label: t`Await Generation`,
        type: "boolean"
      }, {
      //   property: "isInvoice",
      //   label: t`Is Invoice`,
      //   type: "boolean"
      // }, {
      //   property: "totalCostPath",
      //   label: t`Total Cost Path `,
      //   type: "string",
      //   showIf: "documentGeneration.isInvoice"
      // }, {
      //   property: "itemsPath",
      //   label: t`Invoice Items Path `,
      //   type: "string",
      //   showIf: "documentGeneration.isInvoice"
      }, {
        property: "multiple",
        label: t`Is Multiple Documents`,
        type: "boolean"
      }, {
        property: "iteratePath",
        label: t`Data Path For List`,
        type: "string",
        help: "sss",
        showIf: "documentGeneration.multiple"
      }, {
        property: "expr",
        label: t`Jsonata Expression`,
        type: "jnx",
        format: "textarea",
        rows: 7,
        test: {
          param: "Entity Id",
          async onTest({ param, object }) {
            const { documentGeneration } = object;
            const data = await UWEEntityApi.testDocumentExpression({
              id: (param | 0),
              options: {
                  documentName: documentGeneration.documentName,
                  prefix: documentGeneration.prefix,
                  dontSave: documentGeneration.dontSave,
                  awaitGeneration: documentGeneration.awaitGeneration,
                  expr: documentGeneration.expr,
                  iteratePath: documentGeneration.multiple
                    ? documentGeneration.iteratePath
                    : undefined
              }
            }).catch(e => ({error: e.message}));

            return data;
          } 
        }
      }
    ],
    showIf: { expr: "type = 'documentGeneration'" }
  },
  {
    property: "notification",
    label: t`Notification`,
    type: "object",
    properties: [
      {
        property: "service",
        hideInModal: true,
        label: t`Service`,
        type: "select",
        options: [
          { value: "email", label: t`Email` },
          { value: "desktop", label: t`Desktop Notification` }
        ]
      },
      {
        property: "scheduled",
        hideInModal: true,
        label: "Notification Scheduled",
        type: "boolean"
      },
      {
        property: "interval",
        hideInModal: true,
        label: "Run In",
        type: "string",
        showIf: { expr: "notification.scheduled" }
      },
      {
        property: "timeUnit",
        hideInModal: true,
        label: "Time Unit",
        type: "select",
        options: INTERVAL_UNITS,
        showIf: { expr: "notification.scheduled" }
      },
      {
        property: "from",
        hideInModal: true,
        label: "Calculate From",
        type: "string",
        showIf: { expr: "notification.scheduled" }
      },
      {
        property: "senderRole",
        label: t`Sender Role`,
        type: "string"
      },
      {
        property: "subject",
        label: t`Subject`,
        type: "string"
      },
      {
        property: "message",
        label: t`Message`,
        type: "string",
        format: "textarea",
        rows: 6,
      },
      {
        icon: "fa fa-flask",
        label: t`Test Notification`,
        hideInModal: true,
        type: "button",
        onClick({path, fieldProps, scope, object, onChange}) {
          scope.setTestNofitication({path, fieldProps, scope, object, onChange});
        }
      }
    ],
    showIf: { expr: "type = 'notification'" }
  },
  {
    property: "transitionName",
    label: t`Transition Name`,
    type: "string",
    showIf: { expr: "transitions ~> $count > 1" }
  },
  {
    property: "allowManualTransition",
    label: t`Allow Manual Transition`,
    type: "boolean",
  },
  {
    property: "transitionTime",
    label: t`Wait Settings`,
    type: "object",
    properties: [
      {
        property: "method",
        label: t`Wait Method`,
        type: "select",
        options: [
          { value: "delay", label: t`Transition After Specified Minutes Elapsed` },
          { value: "atTime", label: t`Transition At Specified Time` }
        ]
      },
      {
        property: "isParameterized",
        label: t`Time Is Parameterized`,
        type: "boolean",
        showIf: { expr: "transitionTime.method = 'delay'" }
      },
      {
        property: "delay",
        type: "object",
        flex: true,
        properties: [
          {
            property: "value",
            class: "",
            label: t`Delay Amount`,
            type: "string", isNumber: true,
            showIf: { expr: "transitionTime.isParameterized != true" }
          },
          {
            property: "path",
            class: "",
            label: t`Variable Path`,
            type: "string", isNumber: true,
            showIf: { expr: "transitionTime.isParameterized = true" }
          },
          {
            property: "unit",
            type: "select",
            label: "Unit",
            class: "",
            options: INTERVAL_UNITS
          }
        ],
        showIf: { expr: "transitionTime.method = 'delay'" }
      },
      {
        property: "path",
        label: t`Variable Path`,
        type: "string",
        showIf: { expr: "transitionTime.method = 'atTime'" }
      }
    ],
    showIf: { expr: "type = 'wait'" }
  },
  {
    property: "onArrive",
    label: t`On Arrive Action`,
    type: "json"
  },
  {
    property: "selector",
    label: t`Branch Selector`,
    type: "json",
    showIf: { expr: "type = 'branch'" }
  },
  {
    property: "transitions",
    label: t`Transitions`,
    type: "array",
    items: {
      type: "object",
      properties: [
        {
          property: "name",
          label: t`Name`,
          type: "string"
        },
        {
          property: "next",
          label: t`Next`,
          type: "select",
          optionsFromScope: "steps",
          options: true
        },
        {
          property: "action",
          label: t`Action`,
          type: "json"
        },
        {
          property: "buttonColor",
          label: t`Button Color`,
          type: "select",
          options: [
            { value: "#77777a", label: t`System` },
            { value: "#d26a5f", label: t`Red` },
            { value: "#0b136d", label: t`Blue` },
            { value: "#28a745", label: t`Green` },
            { value: "#eac15d", label: t`Yellow` }
          ],
          showIf: { expr: "type = 'emailForm'" }
        }
      ]
    }
  }
];