import { Button } from "reactstrap";


function Buttons({
    buttons,
    prop,
    object, path, 
    value, scope
}){
    return <>{
        (buttons || []).map(({icon, text, onClick, tooltip}, idx) => (
            <Button key={idx}
                title={tooltip}
                onClick={() => {
                    onClick({
                        id: idx,
                        prop,
                        object, path, 
                        value, scope
                    });
                }}
            >
                {icon ? <i className={icon} /> : null}
                {text}
            </Button>
        ))
    }</>;
}


export default Buttons;