import React, { useEffect, useRef } from "react";
import InAxis, { useInAxisComponent } from "../../../components/SVGDrawer/InAxis";
import useTextWrapping from "../../../components/SVGDrawer/useTextWrapping";


const GAP_SIZE = 30;

const GAP_PAD = 10;

function SwimmingLane({
    idx,
    x, y, width, height,
    title,
    minLines,
    gap=GAP_SIZE,
    dispatchAction,
}) {
    const ref = useRef();
    const lines = useTextWrapping({ content: title, ref, maxWidth: height * .83 });
    const _lineCount = lines?.length || 1;
    useEffect(() => {
        if (_lineCount) {
            dispatchAction.setSwimmingLaneLineCount({idx, lineCount: _lineCount});
        }
    }, [_lineCount]);

    const lineCount = minLines > _lineCount ? minLines : _lineCount;
    const gap_pad = GAP_PAD + (1 - _lineCount / lineCount) * gap / 2;

    useInAxisComponent("yBack", () => (
        <g transform={`translate(0, ${y | 0})`} >
            <rect width={`${width - gap}px`} height={`${height}px`}
                stroke="#000" fillOpacity={0}
            />
        </g>
    ), [y, width, height, gap]);

    useInAxisComponent("y", () => (
        <g transform={`translate(0, ${y | 0})`} >
            <rect width={`${lineCount * gap}px`} height={`${height}px`}
                stroke="#000" fill="#fff"
            />
            <g transform={`translate(${gap_pad}, ${(height / 2) | 0})`}>
                <text ref={ref} y=".9em" alignmentBaseline="top" textAnchor="middle" transform="rotate(-90)">{
                    (lines || []).map((line, idx) => <tspan key={idx} x="0" dy={idx ? "1.2em" : ""}>{line}</tspan>)
                }</text>
            </g>
        </g>
    ), [y, lineCount, gap, height, ref, gap_pad, lines]);

    return (null);
}


export default SwimmingLane;