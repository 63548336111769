import { useState, useEffect, useMemo, useCallback } from 'react';
import useDragEffect from './useDragEffect';
import useActionReducer from '../../util/useActionReducer';


function usePanZoom(props){
    const {
        zoom: propZoom,
        center: propCenter,
        coordCenter: propCoordCenter,
        _onImageClick,
        onPanStart,
        onPanStop,
        width: propWidth, height: propHeight, minZoom: propMinZoom=0,
        maxZoom: propMaxZoom=6, pct: propPct, clampPan: propClampPan,
        canPan, canZoom,
        onWheel: prop_onWheel,
    } = props
    const syncProps = {
        width: propWidth, height: propHeight, minZoom: propMinZoom,
        maxZoom: propMaxZoom, pct: propPct, clampPan: propClampPan,
        coordCenter: propCoordCenter, center: propCenter
    };
    const [state, dispatch] = useActionReducer(actions, syncProps);
    const {center, zoom, w2, h2, pct} = state;

    useEffect(() => { dispatch.synchronize(syncProps); }, [
        propWidth, propHeight, propMinZoom,
        propMaxZoom, propPct, propClampPan,
        propCoordCenter, propCenter
    ]);
    useEffect(() => {if (propZoom) dispatch.setZoom(propZoom);}, [dispatch, propZoom]);

    const onWheel = useCallback((
        prop_onWheel || (canZoom ? ({deltaY}) => dispatch.applyZoom(deltaY > 0) : undefined)
    ), [prop_onWheel, canZoom]);

    const onDrag = useCallback((idx, x, y, dx, dy) => canPan ? (
        dispatch.moveCenter({ dx, dy })
    ) : null, [canPan, dispatch]);

    const [
        viewport2DataCoords,
        data2ViewportCoords,
    ] = useMemo(() => {
        const zoompct = zoom * pct;
        const [ox, oy] = [w2 - center[0] * zoom, h2 - center[1] * zoom];

        return [
            ([x, y], options) => {
                const {isDelta, toInt} = options || {};

                if (!isDelta) {
                    x -= ox;
                    y -= oy;
                }

                const pt = [x / zoompct, y / zoompct];

                return toInt ? pt.map(v => v | 0) : pt;
            },

            ([x, y], options) => {
                const {isDelta, toInt} = options || {};

                const pt = [x * zoompct, y * zoompct];

                if (!isDelta) {
                    pt[0] += ox;
                    pt[1] += oy;
                }

                return toInt ? pt.map(v => v | 0) : pt;
            },
        ];
    }, [center, zoom, w2, h2, pct]);

    const { onMouseDownDelegate: onImageMouseDown, onClickDelegate: onImageClick } = useDragEffect({
        onDrag,
        onDragStart: onPanStart,
        onDrop: onPanStop,
        onClick: _onImageClick,
    });

    return {
        center, zoom,
        onWheel,
        viewport2DataCoords,
        data2ViewportCoords,
        onImageClick, onImageMouseDown,
        dispatch
    }
}



const actions = {
    initialize({ width, height, minZoom, maxZoom, pct, clampPan }, state){
        return this.synchronize({ width, height, minZoom, maxZoom, pct, clampPan }, state);
    },
    synchronize({ width, height, minZoom, maxZoom, pct, clampPan, center, coordCenter }, state) {
        const { zoom = 1 } = state;
        const w2 = width / 2;
        const h2 = height / 2;
        const newstate = {
            width, height, minZoom, maxZoom, pct, clampPan,
            zoom: Math.max(2 ** -5, zoom),
            w2, h2,
        };

        if (!state.hasMoved) {
            if (center) {
                Object.assign(newstate, this.setCenter(center, state));
            } else if (coordCenter) {
                Object.assign(newstate, this.setCoordCenter(coordCenter, state));
            } else {
                Object.assign(newstate, this.setCenter([w2, h2], state));
            }
        }

        return newstate;
    },
    moveCenter({dx, dy}, state){
        const { center, zoom } = state;
        return {
            ...this.setCenter([
                center[0] - dx / zoom,
                center[1] - dy / zoom
            ], state),
            hasMoved: true
        };
    },
    applyZoom(zoomOut, state){
        const { zoom } = state;
        return this.setZoom(zoom * 2 ** ((1 - 2 * zoomOut) / 4), state);
    },
    setCoordCenter(coordCenter, state) {
        return this.setCenter([
            coordCenter[0] * state.pct,
            coordCenter[1] * state.pct,
        ], state);
    },
    setCenter(center, { zoom, center: currentCenter, clampPan, w2, h2, width, height }) {
        zoom = zoom || 1;
        const [cx, cy] = center || [w2, h2];
        const w2z = w2 / zoom;
        const h2z = h2 / zoom;
        const cx2 = clampPan ? Math.max(w2z, Math.min(cx, width - w2z)): cx;
        const cy2 = clampPan ? Math.max(h2z, Math.min(cy, height - h2z)): cy;
        const diff = currentCenter ? (currentCenter[0] - cx2) ** 2 + (currentCenter[1] - cy2) ** 2 : null;
        return diff === null || diff > 1e-8 ? {center: [cx2, cy2]} : null;
    },
    setZoom(zoom, {minZoom, maxZoom}) {
        zoom = Math.max(2 ** minZoom, Math.min(zoom || 1, 2 ** maxZoom));
        return {zoom};
    }
}

export default usePanZoom;