import React from 'react';
import { Button, CardBody, Card, ListGroup, ListGroupItem } from 'reactstrap';
import { useHistory } from 'react-router';
import InternalLayout from '../components/layouts/InternalLayout';
import useTitle from "../util/useTitle";
import { useZohoDeskWidgetCallFunction } from "../util/useZohoDeskWidget";
import Notification from '../components/Notification';


const DOCUMENTS = [
    // { title: "Requisición de Materiales con la hoja de continuación", url: "https://asg.pr.gov/Formularios/Compras%20y%20suministros/REQUISICION%20DE%20MATERIALES%20CON%20LA%20HOJA%20DE%20CONTINUACION%20(SC-1001).pdf"},
    // { title: "Adiestramiento Herramienta Padawan (Extensión de JEDI)", url: "https://padawanprodstorage.blob.core.windows.net/padawanprodpublicstorage/ADIEST_1.PDF"},
];


const VIDEOS = [
    // { title: "Video 1", url: "https://www.youtube.com/embed/GDZOa4pLl54"},
    // { title: "Manejo desde Celular", url: "https://www.youtube.com/embed/EwyglCkyLvI"},
];


export function Help(props) {
    const history = useHistory();
    useTitle("Ayuda");

    const zdwcf = useZohoDeskWidgetCallFunction();
    // const zdwcf = () => {};

    return (<InternalLayout>
        <div>
            {DOCUMENTS.length || VIDEOS.length ? (<>
            <h1>Documentaci&oacute;n</h1><br />
            <p>Aquí puedes encontrar los documentos de apoyo para UWE </p>
            {DOCUMENTS.length ? (<>
                <h3>Documentos</h3>
                <ListGroup>
                    {DOCUMENTS.map(({title, url}, idx) => (<ListGroupItem key={idx}>
                        <a href={url} target="_blank" rel="noreferrer">{title}</a>
                    </ListGroupItem>))}
                </ListGroup>
                <br />
            </>) : null}
            <h3>Videos de Entrenamiento</h3>
            <div className="d-flex flex-wrap flex-row">
                {VIDEOS.map(({title, url}, idx) => (<Card className="mb-4">
                    <CardBody key={idx}>
                        <h4 className="text-default">{title}</h4>
                        <iframe width="560" height="315" src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>))}
                {!(VIDEOS?.length) ? (
                    <Notification color="warning">Al momento no se encuentra material en esta sección. Verifique de nuevo pronto.</Notification>
                ) : ""}
            </div>
            <br />
            </>) : null}
            <h3>Crear un Ticket</h3>
            <p>¿Necesitas ayuda directo al equipo? Crea un ticket.</p>
            <Button onClick={() => {history.push(`/create-ticket`)}} color="primary">
                Crear Ticket
            </Button>
            {/* <Button onClick={() => zdwcf()} color="primary">
                Crear Ticket
            </Button> */}
        </div>
    </InternalLayout>);
}

export default Help;
