import React, { useMemo } from "react";
import { FormGroup } from "reactstrap";
import { useJnx } from "../../util/jnx";
import { concatenatePaths } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useOpenModal } from "../FormModal";
import getPathFromId from "../../util/getPathFromId";


function IconCheckField(props) {
    const {
        formData,
        formContext: {
            setFormDataValues,
            sideChannel,
            openModal,
        },
        readonly: propReadonly,
        disabled,
        idSchema: { $id },
        schema: {
            title,
            icon = 'fa fa-check'
        },
        uiSchema: {
            'ui:checkBoxClassName': className = 'btn-secondary',
            'ui:checkBoxCheckedClassName': checkedClassName = 'btn-success',
            'ui:readonly': uiReadonly,
            'ui:onChange': onChangeExpr,
            'ui:dontToggle': dontToggle,
            'ui:exclusiveCheckgroup': exclusiveCheckgroup
        },
        required,
        onChange: propOnChange
    } = props;

    const readonly = propReadonly || uiReadonly;

    const rootFormData = useSideChannelSubscription(sideChannel, 0) || {};
    const path = useMemo(() => getPathFromId($id), [$id]);

    const jnxOnChange = useJnx(onChangeExpr, (_, onChangeJnx) => {
        if (onChangeJnx) onChangeJnx.evalAsync(rootFormData, path, {
            openModal
        }).then(result => {
            console.log("onChangeJnx result:", result);
        });
    }, [openModal, path, rootFormData]);

    function onClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (readonly || disabled) return;

        const newValue = dontToggle ? true : !formData;

        if (exclusiveCheckgroup && newValue) {
            const dataValues = exclusiveCheckgroup.reduce((_, otherCheckRelPath) => {
                const otherPath = concatenatePaths(path, otherCheckRelPath);
                _[otherPath] = false;
                return _;
            }, {
                [path]: true
            });
            setFormDataValues(dataValues);
        } else {
            propOnChange(newValue);
        }

        if (jnxOnChange) jnxOnChange(); 
    }

    return (
        <FormGroup disabled={readonly || disabled} className="form-group-icon-check-field">
            <button
                id={$id}
                className={`icon-check-field btn ${
                    (checkedClassName && formData) ? checkedClassName : className
                } ${formData ? 'checked' : ''}`}
                color="secondary"
                disabled={readonly || disabled} required={required}
                onClick={onClick}
            >
                {formData ? <i className={icon} /> : <span>{" "}</span>}
            </button>
        </FormGroup>
    );
}


export default IconCheckField;