import React, { useCallback, useMemo, useState } from "react";
import { Button, Input, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CodeMirror from '@uiw/react-codemirror';
import { json } from "@codemirror/lang-json";
import JnxEditor from "../../JnxEditor/JnxEditor";
import CollapsableInputGroup from "../../CollapsableInputGroup";
import useLoader from "../../../util/useLoader";
import Loader from "../../Loader";
import Notification from "../../Notification";
import useActionReducer from "../../../util/useActionReducer";


const PATTERN = { type: "jnx" };

function JnxField({ fieldProps: prop, value, object, onChange }) {
    const { label } = prop;
    const [state, dispatch] = useActionReducer(JnxFieldActions, prop, "jnx field");
    const { showModal } = state;
    
    return <>
        {label ? <label className="title">{label}</label> : null}
        <JnxFieldComponent
            value={value} object={object} onChange={onChange}
            state={state} dispatch={dispatch}
        />
        {showModal ? (
        <Modal isOpen container="body"
            className="modal-primary maximized"
            toggle={dispatch.toggleModal}
            backdrop
        >
            <ModalHeader
                toggle={dispatch.toggleModal}
            >
                {label}
            </ModalHeader>
            <ModalBody>
                <JnxFieldComponent modal
                    value={value} object={object} onChange={onChange}
                    state={state} dispatch={dispatch}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={dispatch.toggleModal}>Close Modal</Button>
            </ModalFooter>
        </Modal>
        ) : null}
    </>
}

function JnxFieldComponent({
    modal,
    value, object, onChange,
    state, dispatch,
}){
    const { testResult } = state;
    const [testing, testError, testFn] = useLoader();

    return <>
        <JnxEditor value={value} onChange={onChange}
            hideScope={state.hideScope}
            hideDebug={state.hideDebug}
            inputs={
                <JnxFieldInputButtons
                    object={object}
                    state={state} dispatch={dispatch}
                    testFn={testFn} testing={testing}
                />
            }
            maxHeight={
                modal ? (
                    testing || testError || testResult ? "36vh" : "72vh"
                ): "15em"
            } />
        {testing ? (
            <Loader />
        ) : (testError ? (<>
            <div>Results</div>
            <Notification error={testError} />
        </>) : (testResult ? <>
            <div>Results</div>
            <CodeMirror
                readOnly
                value={testResult} 
                width="100%"
                maxHeight="30vh"
                extensions={[json()]}
            />
        </> : null))}
    </>
}

function JnxFieldInputButtons({
    object,
    state: { test, param, showModal, hideModal },
    dispatch,
    testFn,
    testing,
}){
    const performTest = useCallback(() => {
        dispatch.setTestResult();
        if (test?.onTest) testFn(async () => {
            const data = await test.onTest({ param, object });
            dispatch.setTestResult(JSON.stringify(data, null, 4))
        });
    }, [test, param, object]);

    return <>
        {test ? (<CollapsableInputGroup title="Test" icon="fa fa-flask" onClose={() => dispatch.resetTest()} >
            <Input value={param} placeholder={test.param} style={{maxWidth: '10em'}} onChange={({target: {value}}) => dispatch.setParam(value)}/>
            <InputGroupAddon addonType="prepend">
                <Button onClick={() => performTest()} disabled={testing} >
                    Test
                </Button>
            </InputGroupAddon>
        </CollapsableInputGroup>) : null}
        {hideModal ? null : <InputGroupAddon addonType="prepend">
            <Button outline={!showModal} onClick={() => dispatch.toggleModal()} title="Open in Modal"> 
                <i className="fa fa-window-restore" />
            </Button>
        </InputGroupAddon>}
    </>;
}


const JnxFieldActions = {
    initialize: ({ test, hideDebug, hideScope, hideModal }) => ({ test, hideDebug, hideScope, hideModal }),
    setParam: (param) => ({ param }),
    setTestResult: (testResult) => ({ testResult }),
    resetTest: () => ({ param: undefined, testResult: undefined }),
    toggleModal: (_, {showModal}) => ({ showModal: !showModal }),
}


JnxField.pattern = PATTERN;


export default JnxField;