import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import CodeMirror from '@uiw/react-codemirror';
import { keymap } from '@codemirror/view';
import { Prec } from "@codemirror/state";
import { jsonata } from '@jsonhero/codemirror-lang-jsonata';
import { lintGutter, forEachDiagnostic } from "@codemirror/lint";

// import JsonLinter from "./extensions/linter"
import BracketInserter from "../JsonEditor/extensions/brackets";

// import ToolbarButton from "./ToolbarButton";


import { DEFAULT_SCOPE, normalizeToExprObject } from "../../util/jnx";
import useElementSizeCheckHook from "../../util/useElementSizeCheckHook";
import CollapsableInputGroup from "../CollapsableInputGroup";

// const JsonataLinter = () => linter(jsonataParseLinter());


function JnxEditor({
    value, onChange: propOnChange,
    defaultScope = DEFAULT_SCOPE,
    hideScope,
    hideDebug,
    inputs,
    maxHeight,
}) {
    // Initial prop handling
    const valueObj = useMemo(() => normalizeToExprObject(value), [value]);
    const valueObjRef = useRef();
    valueObjRef.current = valueObj;
    const { expr, scope: _scope, debug } = valueObj;
    const scope = _scope ?? defaultScope;
    const isDefaultScope = scope === defaultScope;

    // The expr string of the editor (given in value prop)
    const [editorExpr, setEditorExpr] = useState("");

    // Flags
    const [isPretty, setIsPretty] = useState(true);
    const [hasError, setHasError] = useState(false);

    // Ref for the editor, to modify it from toolbar or other buttons.
    const editorRef = useRef();
    const editorRefCallack = (editor) => {
      if (!editorRef.current && editor?.editor && editor?.state && editor?.view) {
        editorRef.current = editor; 
      }
    }

    const handleChange = useCallback((value) => {
        const newValue = {...valueObjRef.current, ...value};
        if (!newValue.debug && (newValue.scope === defaultScope || newValue.scope === null)) {
            propOnChange(newValue?.expr ? newValue.expr : undefined);
        } else {
            propOnChange(newValue?.expr ? newValue : newValue);
        }
    }, [propOnChange, defaultScope]); 

    // When form prop object changes, update the editor's JSON representation
    useEffect(() => {
        setEditorExpr(expr);
    }, [expr]);

    // ----------
    // -- Editor
    // ----------

    // 1) Checks if linter found any diagnostics
    const onEditorUpdate = useCallback((viewUpdate) => {
        const state = viewUpdate.view.state;
        let errored = false;
        forEachDiagnostic(state, (diag, from, to) => {
            const msg = `${diag.severity}: ${diag.message}`;
            console.log(msg);
            errored = true;
        });

        setHasError(errored && !!state?.doc?.length);
    }, [setHasError]);

    // Called when editor is changed: parse JSON representation 
    const onTextChange = useCallback((value, viewUpdate) => {
        handleChange({expr: value});
    }, [handleChange]);

    return <div style={{position: "relative"}}>
        <InputGroup size="sm">
            {hideScope ? null : <CollapsableInputGroup
                title="Scope" icon="fa fa-map-marker-alt"
                shouldBeOpen={!isDefaultScope}
                onClose={() => handleChange({scope: defaultScope})}
            >
                <Input value={scope} style={{maxWidth: '10em'}} onChange={(e) => handleChange({scope: e.target.value})} />
            </CollapsableInputGroup>}
            {hideDebug ? null : <CollapsableInputGroup
                title="Debug Tag" icon="fa fa-bug"
                shouldBeOpen={!!debug}
                onOpen={() => handleChange({debug: "debug tag"})}
                onClose={() => handleChange({debug: null})}
            >
                <Input value={debug} style={{maxWidth: '10em'}} onChange={(e) => handleChange({debug: e.target.value})} />
            </CollapsableInputGroup>}
            {inputs}
        </InputGroup>
        {/* <textarea
            ref={ref}
            type="textarea"
            value={currentExpr}
            onChange={onChange}
            rows={rows}
            style={{
                width:'100%',
            }}
        /> */}
        <CodeMirror
            ref={editorRefCallack} 
            value={editorExpr} 
            width="100%"
            maxHeight={maxHeight}
            extensions={[
                // keyMap,
                // jsonata(),
                BracketInserter(),
                ...(editorExpr ? [
                    // JsonataLinter(),
                    lintGutter()
                ]: [])
            ]}
            onChange={onTextChange}
            onUpdate={onEditorUpdate} 
        />
    </div>;
}


export default JnxEditor;