import { faCalculator, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { faFileLines, faEnvelope, faClock } from '@fortawesome/free-regular-svg-icons'

export const STEP_TYPES = {
    form:               { shape: "rect"     , icon: "fa fa-clipboard-list" , svgIcon: faFileLines   , canHavePermissions: true},
    calculation:        { shape: "rect"     , icon: "fa fa-calculator"     , svgIcon: faCalculator                            },
    branch:             { shape: "diamond"  , icon: "fa fa-traffic-light"                                                            },
    notification:       { shape: "rect"     , icon: "fa fa-envelope"       , svgIcon: faEnvelope                              },
    emailForm:          { shape: "rect"     , icon: "fa fa-file-lines"     , svgIcon: faFileLines   , canHavePermissions: true},
    documentGeneration: { shape: "document" , icon: "fa fa-file-pdf"                              , canHavePermissions: true},
    externalProcess:    { shape: "rect-bars", icon: "fa fa-cogs"                                    , canHavePermissions: true},
    subWorkflow:        { shape: "rect-bars", icon: "fa fa-network-wired"  , svgIcon: faNetworkWired, canHavePermissions: true},
    wait:               { shape: "rect-bars", icon: "fa fa-clock"          , svgIcon: faClock       , canHavePermissions: true},
    end:                { shape: "oval"     , icon: "fa fa-flag-checkered"                          , canHavePermissions: true},
}

export const UNKNOWN_STEP_TYPE = { shape: "rect" };

export default function getStepType(type) {
    return STEP_TYPES[type] ?? UNKNOWN_STEP_TYPE;
}
