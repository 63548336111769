import { Trans } from '@lingui/macro';
import React, { useState, useEffect } from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import RoleApi from "../../../api/RoleApi";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import useResourceLoader from "../../../util/useResourceLoader";
import useLoader from "../../../util/useLoader";
import JsonTreeEditor from "../../../components/JsonTreeEditor";
import useSearchParams from "../../../util/useSearchParams";
import RoleEditor from "./RoleEditor";

import JsonEditor from '../../../components/JsonEditor';

const SHOW_TABS = {
    role: 'Role',
    tree: 'Tree',
    json: 'Json',
}

function EditRole({ roleId, setRole: setRoleId}) {
    const [resetCt, setResetCt] = useState();
    const [role, loadingRole, errorLoadingRole, setRole] = useResourceLoader((
        () => roleId === "new" ? {
            order: 0,
            slug: 'new-role',
            name: "New Role",
            image: {
                icon: 'fa fa-file'
            },
            definition: {
                type: "role",
                elements: []
            },
        } : RoleApi.get({ id: roleId })
    ), [resetCt]);

    const [[show, setShow]] = useSearchParams({
        show:'role'
    });

    const [loadingSetRole, errorLoadingSetRole, loadFn] = useLoader();
    const loading = loadingRole || loadingSetRole;
    const error = errorLoadingRole || errorLoadingSetRole;
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    const [dirtyRole, setDirtyRole] = useState();

    const currentRole = dirtyRole || role;

    function save() {
        return loadFn(async () => {
            if (roleId === "new") {
                const saveOS = await RoleApi.create(dirtyRole);
                setRole(saveOS);
                setRoleId(saveOS.id);
            } else {
                const savedOS = await RoleApi.update({id: roleId, ...dirtyRole});
                setRole(savedOS);
            }
        });
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    return ( loading ? (
        <Loader />
    ) : (<>
        {error ? (
            <Notification error={error} />
        ) : null}
        {role ? (<div>
            <h3>{roleId === "new" ? (<>
                <Trans>Creating Role: {role.name}</Trans>
            </>) : (<>
                <Trans>Editing Role: {role.name}</Trans>
            </>)}</h3>
            <UncontrolledDropdown>
                <DropdownToggle caret>{SHOW_TABS[show]}</DropdownToggle>
                <DropdownMenu>{Object.entries(SHOW_TABS).map(([key, title]) => (<DropdownItem key={key} onClick={() => setShow(key)}>
                    {title}
                </DropdownItem>))}</DropdownMenu>
            </UncontrolledDropdown>
            {show === "tree" ? (
                <JsonTreeEditor mode="object" value={currentRole} onChange={setDirtyRole} />
            ): null}
            {show === "json" ? (
                <JsonEditor value={currentRole} onChange={setDirtyRole} onSaveForm={save} />
            ): null}
            {show === "role" ? (
                <RoleEditor role={currentRole} onChange={setDirtyRole} />
            ): null}
            {/* <JSONInput
                placeholder={role}
                width={windowSize[0]*.8} height={windowSize[1]*.7}
                onBlur={onSchemaChange}
                onKeyPressUpdate={false}
                locale={locale}
            /> */}
            <Button color="success" disabled={!dirtyRole} onClick={save}><Trans>Save</Trans></Button>
        </div>) : null}
    </>));
}

export default EditRole;