import React, { useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import determineUniquePropName from "../../util/determineUniquePropName";
import JsonTreeEditorNode from "./JsonTreeEditorNode";
import JsonStringEditor from "./StringEditor";


function JsonObjectEditor({indent, path, node, setObject}) {
    const prefix = useMemo(() => path ? `${path}.` : '', [path]);

    const onRemoveAttr = useCallback(({currentTarget: {attributes: {attr}}}) => {
        attr = attr.value;
        const newNode = {...node};
        delete newNode[attr];
        setObject(path, newNode);
    }, [setObject, path, node]);

    const onSwapAttr = useCallback(({currentTarget: {attributes: {attr, attr2}}}) => {
        attr = attr.value;
        attr2 = attr2.value;
        const v1 = node[attr];
        const v2 = node[attr2];
        const newNode = Object.entries(node).reduce((_, [k, v]) => {
            if (k === attr) _[attr2] = v2;
            else if (k === attr2) _[attr] = v1;
            else _[k] = v;
            return _;
        }, {});

        setObject(path, newNode);
    }, [setObject, path, node]);

    const setSubnodeAttr = useCallback((oldAttr, newAttr) => {
        newAttr = determineUniquePropName(newAttr, node);
        const newNode = Object.entries(node).reduce((_, [k, v]) => {
            if (k === oldAttr) _[newAttr] = v;
            else _[k] = v;
            return _;
        }, {});

        setObject(path, newNode);
    }, [setObject, path, node])

    const onAddItem = useCallback(() => {
        setObject(path, {...node, [determineUniquePropName('attr', node)]: null});
    }, [path, node]);

    const entries = Object.entries(node);

    return <div className="json-tree-editor-node-object">
        {entries.map(([attr, subnode], idx) => (<React.Fragment key={idx}>
            <JsonTreeEditorNode
                indent={indent + 1}
                index={
                    <div className="index">
                        <JsonStringEditor path={attr} node={attr} setObject={setSubnodeAttr} allowMultiline={false} />
                        <Button className="delete-btn" color="light" attr={attr} onClick={onRemoveAttr}><i className="fa fa-times" /></Button>
                    </div>
                }
                path={`${prefix}${attr}`}
                node={subnode} setObject={setObject}
                />
        </React.Fragment>))}
        <div className="lower-buttons">
            <Button color="primary" onClick={onAddItem}>+ Add</Button>
        </div>
    </div>;
}

JsonObjectEditor.isContainer = true;

export default JsonObjectEditor;