import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";


export const TYPES = ["object", "array", "string", "number", "null"]


function JsonSchemaTypeSelector({ type: selectedType, readonly, onChangeType }) {
    const label = (<>
        <i className={`icon icon-json-${selectedType || 'unknown'}`} title={selectedType} />
        {selectedType}
    </>);
    return readonly ? (<div className='json-schema-type-select readonly'>
        {label}
    </div>) : (<UncontrolledDropdown className='json-schema-type-select'>
        <DropdownToggle caret>
            {label}
        </DropdownToggle>
        <DropdownMenu>{TYPES.map(type => (
            <DropdownItem key={type} onClick={() => onChangeType(type)} className={`${type === selectedType ? 'active': ''}`}>
                <i className={`icon icon-json-${type}`}/> {type}
            </DropdownItem>
        ))}</DropdownMenu>
    </UncontrolledDropdown>);
}



export default JsonSchemaTypeSelector;