import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import JsonEditor from "../../JsonEditor";


const PATTERN = { type: "json" };

function JsonField({ fieldProps: prop, value, onChange }) {
    const { label } = prop;
    const [showModal, setShowModal] = useState();

    const buttons = useMemo(() => [
        {
            title: "Open in Modal", onClick: () => setShowModal(!showModal),
            className: "secondary", icon: "fa fa-window-restore",
        }
    ].filter(x => !!x), [
        showModal,
        setShowModal
    ])

    const makeComponent = ({modal} = {}) => (
        <>
            {modal ? null : <label className="title">{label}</label>}
            <JsonEditor value={value} onChange={onChange} maxHeight={modal ? "75vh": "9em"}
                buttons={buttons}
            />
        </>
    )

    return <>
        {makeComponent()}
        {showModal ? (
        <Modal isOpen container="body"
            className="modal-primary maximized"
            toggle={() => setShowModal(false)}
            backdrop
        >
            <ModalHeader
                toggle={() => setShowModal(false)}
            >
                {label}
            </ModalHeader>
            <ModalBody>
                {makeComponent({modal: true})}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => setShowModal(false)}>Close Modal</Button>
            </ModalFooter>
        </Modal>
        ) : null}
    </>
}



JsonField.pattern = PATTERN;


export default JsonField;