import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import useActionReducer from "../../util/useActionReducer";
import useElementSizeCheckHook from "../../util/useElementSizeCheckHook";


function JsonComponent({
    value, onChange: propOnChange,
    minHeight=150,
    height: propHeight
}) {
    const [state, dispatch] = useActionReducer(actions, {});
    const {
        jsonText, hasError,
        isPretty
    } = state;

    const setJsonText = useCallback((jsonText) => {
        try {
            const value = JSON.parse(jsonText);
            dispatch.setJsonText({ jsonText, hasError: false });
            propOnChange(value);
        } catch(e){
            dispatch.setJsonText({ jsonText, hasError: true });
        }
    }, [propOnChange]);

    const onChange = useCallback(({ target: { value }}) => setJsonText(value), [setJsonText]);
    const onKeyDown = useCallback(e => {
        const {target, key} = e;
        if (key === '"') {
            const {selectionStart, selectionEnd, value} = target;
            if (selectionStart === selectionEnd) return;

            e.stopPropagation();
            e.preventDefault();

            const selectedText = value.substring(selectionStart, selectionEnd);

            document.execCommand("insertText", null, `"${selectedText}"`);
        }
    }, [setJsonText]);

    useEffect(() => {
        dispatch.setValue({value});
    }, [value]);

    const [height, setHeight] = useState(propHeight || 150);
    const ref = useElementSizeCheckHook((element) => {
        if (propHeight) return;
        const { offsetParent, offsetTop } = element;
        const opHeight = (offsetParent === document.body) ? window.innerHeight : offsetParent.clientHeight;
        const targetHeight = Math.max(minHeight, (opHeight - offsetTop - 40) | 0);
        if (height !== targetHeight) {
            setHeight(targetHeight);
        }
    });

    return <div ref={ref} style={{
        position: 'relative',
        width:'100%',
        height: `${height}px`
    }}>
        <textarea
            type="textarea"
            value={jsonText || ''}
            onChange={onChange}
            onKeyDown={onKeyDown}
            rows={5}
            style={{ width:'100%', height: '100%' }}
        />
        <div style={{
            position: 'absolute',
            top: '1px',
            right: '1px'
        }}>{hasError ? (
            <i className="fa fa-exclamation" style={{fontSize: '2rem', color: 'red'}} />
        ) : (!isPretty ? (
            <Button title="Make Pretty" onClick={() => dispatch.prettify({})}>
                <i className="fa fa-magic" />
            </Button>
        ) : null)}</div>
    </div>;
}


const actions = {
    prettify(_, { jsonText }) {
        try {
            const prettyJsonText = JSON.stringify(JSON.parse(jsonText), null, 4);
            return {
                jsonText: prettyJsonText,
                prettyJsonText,
                isPretty: true
            };
        } catch {
            return {
                jsonText
            };
        }
    },
    setJsonText({jsonText, hasError}) {
        let prettyJsonText = '';
        try {
            prettyJsonText = JSON.stringify(JSON.parse(jsonText), null, 4);
        } catch {
            // could not parse jsonText
        }

        return ({
            jsonText,
            prettyJsonText,
            isPretty: prettyJsonText === jsonText,
            hasError
        });
    },
    setValue({value}, {jsonText}) {

        const valueJsonText = JSON.stringify(value, null, 4) || "";
        let prettyJsonText = '';

        try {
            prettyJsonText = JSON.stringify(JSON.parse(jsonText), null, 4);
        } catch {
            // could not parse jsonText
        }

        if (valueJsonText !== prettyJsonText) {
            return {
                jsonText: valueJsonText,
                prettyJsonText: valueJsonText,
                isPretty: true,
            };
        }

        return { jsonText };
    }
}

export default JsonComponent;