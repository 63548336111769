import React, { useMemo } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import Notification from "../../../Notification";
import { useJnx } from "../../../../util/jnx";
import getPathFromId from "../../../../util/getPathFromId";

class TabsLayout {
  constructor({
    $id,
    hasToolbar,
    tabNameExpr,
    itemsAreObjectType,
    itemsUiSchema,
    onEmptyMessage,
    positionButtons,
    rowClassNames,
    openModal,
    tabs
  }) {
    this.$id = $id;
    this.hasToolbar = hasToolbar;
    this.itemsAreObjectType = itemsAreObjectType;
    this.itemsUiSchema = itemsUiSchema;
    this.tabNameExpr = tabNameExpr;
    this.onEmptyMessage = onEmptyMessage;
    this.positionButtons = positionButtons;
    this.rowClassNames = rowClassNames;
    this.openModal = openModal;
    this.tabs = tabs;
  }

  wrapArray(children, {
    selectedItem, setSelectedItem, props, onAddClick,
    $id, rootFormData, formContext,
   }) {
    selectedItem = selectedItem ?? 0;
    const {
      "ui:addable": uiAddable = true,
      "ui:removable": removable,
      "ui:removable-label": removableLabel,
      "ui:removableConfirm": removableConfirm,
      "ui:removableConfirmText": removableConfirmText,
      classNames: uiClassNames
    } = this.itemsUiSchema || {};
    const hasAddBtn = props.canAdd && uiAddable;
    const CONFIRM_REMOVE_ITEM = () => [
      "ConfirmationModal",
      ``,
      removableConfirmText || "Confirmar que quiere borrar información entrada",
      [
        { text: "Si", color: "primary", value: true },
        { text: "No", color: "secondary", value: false }
      ]
    ];

    return (
      <>
        <Nav tabs className="form-tabs">
          {this.tabs.map((tab, index) => {
            const itemprops = props.items[index] || {};
            return (
              <NavItem key={index}>
                <NavLink
                  active={index === selectedItem}
                  onClick={() => {
                    setSelectedItem(index);
                  }}
                >
                  <TabName expr={this.tabNameExpr} index={index} tab={tab}
                    $id={$id}
                    rootFormData={rootFormData}
                    formContext={formContext}
                  />
                  {removable && index === selectedItem ? (
                    <Button
                      color="primary2"
                      aria-label="Remove"
                      className="remove-btn"
                      tabIndex="-1"
                      style={{
                        flex: "0 0 32px",
                        paddingLeft: 6,
                        paddingRight: 6,
                        fontWeight: "bold"
                      }}
                      disabled={itemprops.disabled || itemprops.readonly}
                      onClick={
                        removableConfirm
                          ? async () => {
                              if (
                                await this.openModal(...CONFIRM_REMOVE_ITEM())
                              ) {
                                await itemprops.onDropIndexClick(
                                  itemprops.index
                                );
                              }
                            }
                          : async () => {
                              await itemprops.onDropIndexClick(itemprops.index);
                            }
                      }
                    >
                      {removableLabel || "X"}
                    </Button>
                  ) : null}
                </NavLink>
              </NavItem>
            );
          })}
          {hasAddBtn && (
            <NavItem>
              <NavLink
                onClick={onAddClick}
                disabled={props.disabled || props.readonly}
              >
                <i className="fa fa-plus" />
                {this.itemsUiSchema["ui:addButtonText"]
                  ? ` ${this.itemsUiSchema["ui:addButtonText"]}`
                  : null}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent className="form-tab-content" activeTab={selectedItem}>
          {children}
        </TabContent>
      </>
    );
  }

  wrapBody(children) {
    return children;
  }

  wrapLoader(children) {
    return children;
  }

  makeHeaders() {
    return null;
  }

  makeEmptyMessage() {
    return (
      <div>
        <Notification color="warning">{this.onEmptyMessage}</Notification>
      </div>
    );
  }

  wrapItemErrorList(children) {
    return children;
  }

  wrapItemField({ name, uiSchema }, children) {
    return (
      <div key={name} className={(uiSchema[name] || {})["ui:cellClassName"]}>
        {children}
      </div>
    );
  }

  wrapItem(
    id,
    props,
    onAddClick,
    rowIdx,
    rowCount,
    children,
    buttons,
    rowClassNames
  ) {
    return (
      <TabPane tabId={rowIdx} key={rowIdx}>
        <div
          id={id}
          className={`tab-pane-item ${props.className} ${rowClassNames}`}
        >
          {children}
        </div>
      </TabPane>
    );
  }

  wrapAddButton(children) {
    // return <div>{children}</div>;
  }

  makeSimpleItem(props) {
    return <div>{props.children}</div>;
  }
}


function TabName({ $id, rootFormData, formContext, expr, index, tab }){
  const path = useMemo(() => `${getPathFromId($id)}.${index}`, [$id, index]);
  const jnx = useJnx(expr);
  return jnx ? jnx.eval(rootFormData, path, {
    index, tab, formContext,
  }) : `Tab ${index}`;
}


export default TabsLayout;
