import { useContext, useEffect } from "react";
import Context2D from "./Context";
import useUID from "../../util/useUID";


function InAxis({
    axis,
    children,
    dependencies
}) {
    const uid = useUID("axisEl");
    const { setOverlay } = useContext(Context2D);
    useEffect(() => {
        let isSet = false;
        const timeout = setTimeout(() => {
            isSet = true;
            setOverlay(axis, uid, children)
        }, 10);
        return () => {
            if(isSet) {
                setOverlay(axis, uid, null);
            }else {
                clearTimeout(timeout);
            }
        }
    }, [axis, uid, children]);

    return null;
}

export function useInAxisComponent(axis, componentFn, dependencies) {
    const uid = useUID("axisEl");
    const { setOverlay } = useContext(Context2D);

    useEffect(() => {
        setOverlay(axis, uid, componentFn());

        return () => {
            setOverlay(axis, uid, null);
        }
    }, [axis, uid, ...dependencies]);
}


export default InAxis;