import { useCallback, useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CodeMirror from '@uiw/react-codemirror';
import { json } from "@codemirror/lang-json";
import { FIELD_MAP } from "./fields";
import { getObject } from "../../util/mapObject";
import ObjectEditorField from "../_formEditor/ObjectEditorField";
import StringField from "../_formEditor/ObjectEditorField/StringField";
import Loader from "../Loader";
import Notification from "../Notification";
import useLoader from "../../util/useLoader";
import UWEEntityApi from "../../api/UWEEntityApi";

const NOTIFICATION_FIELDS = (
    FIELD_MAP.filter(f => f.property === "notification")
    .shift()?.properties
    ?.filter(p => !p.hideInModal)
);

function TestNotificationModal({path: _path, scope, object, onChange, onClose}){
    // const path = useMemo(() => _path?.startsWith(".") ? _path.substring(1) : _path [_path]);
    const pathPrefix = useMemo(() => 'notification.', []);
    const [entityId, setEntityId] = useState();
    const [testing, testError, testFn] = useLoader();
    const [testResult, setTestResult] = useState();
    const performTest = useCallback(() => {
        setTestResult();
        testFn(async () => {
            const data = await UWEEntityApi.testInternalNotificationMessage({ id: entityId, object });
            setTestResult(data);
        });
    }, [entityId, testFn, setTestResult]);

    return <Modal isOpen container="body"
        className="modal-primary maximized"
        toggle={onClose}
        backdrop
    >
        <ModalHeader toggle={onClose} >
            {object.title} - Test Notification
        </ModalHeader>
        <ModalBody>
            <div className="col-lg-12">
                <StringField label="Entity Id" value={entityId} isNumber onChange={id => setEntityId(id)}/>
            </div>
            {NOTIFICATION_FIELDS.map((prop, idx) => {
                const subpath =`${pathPrefix}${prop.property}`;
                return (
                    <ObjectEditorField
                        key={idx}
                        id={idx}
                        path={subpath}
                        scope={scope}
                        fieldProps={prop}
                        object={object}
                        value={object ? getObject(object, subpath) : null}
                        onChange={value => onChange(subpath, value)}
                    />
                );
            })}
            <Button disabled={!entityId} onClick={performTest}>Run Test</Button>
            {testing ? (
                <Loader />
            ) : (testError ? (<>
                <div>Results</div>
                <Notification error={testError} />
            </>) : (testResult ? <>
                <div>Results</div>
                <UserResult title="Sender" user={testResult.sender} role={testResult.senderRole} />
                <UserResult title="Recipient" user={testResult.user} role={testResult.userRole} />
                <ResultField title="Subject">{testResult.subject}</ResultField>
                <ResultField title="Message"><textarea className="col" rows={5}>{testResult.message}</textarea></ResultField>
                <ResultField title="Link"><a href={testResult.link} target="_blank" rel="noreferrer">{testResult.link}</a></ResultField>
            </> : null))}
        </ModalBody>
        <ModalFooter>
            <Button onClick={onClose}>Close Modal</Button>
        </ModalFooter>
    </Modal>
}

function ResultField({title, children}) {
    return (<div className="row">
        <label className="col-1">{title}</label>
        <div className="col">{children}</div>
    </div>)
}

function UserResult({title, user, role}) {
    return (<div className="row">
        <label className="col-1">{title}</label>
        <div className="col-3">{user.name}</div>
        <div className="col-3">{user.email}</div>
        <div className="col-2">{user.position}</div>
        <div className="col-3">{role}</div>
    </div>);
}


export default TestNotificationModal;