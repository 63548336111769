import React, { useCallback } from 'react';
import ObjectEditorField from './ObjectEditorField';

const PATTERN = { type: "object" };


function ObjectField({ id, path, fieldProps: prop, value: objectValue, onChange: objectOnChange, scope, object }) {

    const onChange = useCallback((prop, value) => {
        const newValue = {
            ...objectValue,
            [prop.property]: value
        };
        objectOnChange(newValue);
    }, [prop, objectOnChange, objectValue]);
    const pathPrefix = path ? "" : `${path}.`;

    return (<>
        {prop.label ? <label className="title">{prop.label}</label> : null}
        <div className={prop.flex ? "d-flex" : ""}>
        {Object.values(prop.properties).map((item, idx) => (
            <ObjectEditorField key={idx} id={idx}
                path={`${pathPrefix}.${idx}`}
                fieldProps={item}
                scope={scope}
                object={object}
                value={objectValue ? objectValue[item.property] : null}
                onChange={value => onChange(item, value)}
            />
        ))}
        </div>
    </>)
}

ObjectField.pattern = PATTERN;

export default ObjectField;