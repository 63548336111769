import React, { useState } from "react";
import useElementSizeCheckHook from "../../util/useElementSizeCheckHook";
import AdjusterEdge from "./AdjusterEdge";


function EditorContainer({ children, noScroll }) {
    const [height, setHeight] = useState(10);
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(30);
    const editorRef = useElementSizeCheckHook((element) => {
        const { offsetParent, offsetTop } = element;
        const opHeight = (offsetParent === document.body) ? window.innerHeight : offsetParent.clientHeight;
        const targetHeight = (opHeight - offsetTop - 40) | 0;
        if (height !== targetHeight) {
            setHeight(targetHeight);
        }
    });

    const [mainContent, sidebarContent] = children.reduce((_, child) => {
        const view = child?.props?.view || child?.type?.defaultProps?.view;
        if (child) {
            const idx = (view === 'sidebar') ? 1 : 0;
            _[idx].push(child);
        }
        return _;
    }, [[], []]);

    if (sidebarContent.length) {
        sidebarContent.forEach((child, idx) => {
            child = {...child, props: {...child.props}};
            child.props.sidebarChildIdx = idx;
            child.props.sidebarChildCount = sidebarContent.length;
            sidebarContent[idx] = child;
        })
    }

    const contentWidth = collapseSidebar ? 100 : 100 - sidebarWidth;

    return <div className="editor-container" style={{
        height: `${height}px`
    }} ref={editorRef}>
        <div className="editor-main-container" style={{
            width: `${contentWidth}%`,
            ... noScroll ? {overflow: 'hidden'} : {}
        }}>
            {mainContent}
        </div>
        {!collapseSidebar ? (<>
            <AdjusterEdge
                parentRef={editorRef} vertical fromRight percentage position={sidebarWidth} setPosition={setSidebarWidth}
            />
            <div className="editor-sidebar" style={{
                height: '100%',
                width: `${sidebarWidth}%`,
            }}>
                {sidebarContent}
            </div>
        </>): null}
        <div style={{position: "absolute", left: `${contentWidth}%`, bottom: 0, transform: 'translateX(-100%)', zIndex: 110}}>
            <button className="btn btn-secondary btn-sm" onClick={() => setCollapseSidebar(!collapseSidebar)} ><i className={`fa fa-chevron-${collapseSidebar ? "left" : "right"}`} /></button>
        </div>
    </div>;
}


export default EditorContainer;