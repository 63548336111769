import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { faFileLines, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import SelectionShape from "./SelectionShape";
import { wrapTextContent } from "../../../util/svgUtil";
import TypesSet from "../../../util/TypesSet";
import useTextWrapping from "../useTextWrapping";


function Shape({
    className,
    content,
    x, y,
    shape,
    icon,
    selected,
    draggable=true,
    onDrag: propOnDrag,
    onDrop: propOnDrop,
    width = 10,
    height = 10,
    pad = 10,
    autoCalcSize = undefined,
    onSetSize = undefined,
    onClick = undefined
}) {
    const ref = useRef();
    draggable = draggable & selected;

    const [_isDragging, setIsDragging] = useState();
    const [onDrag, onDrop] = useMemo(() => [
        (...args) => {
            if (!_isDragging) setIsDragging(true);
            return propOnDrag ? propOnDrag(...args) : null;
        },
        (...args) => {
            if (_isDragging) setIsDragging(false);
            return propOnDrop ? propOnDrop(...args) : null;
        }
    ], [_isDragging, propOnDrag, propOnDrop]);
    const isDragging = _isDragging && draggable;

    const [dx, dy] = useMemo(() => isDragging ? [-5, -5] : [0, 0], [isDragging]);
    const [hover, setHover] = useState();

    const lines = useTextWrapping({ ref, content, maxWidth: 200 });

    useEffect(() => {
        if (autoCalcSize === false || !onSetSize || !lines) return;
        const bbox = ref?.current?.getBBox();
        const {width, height} = bbox || {};
        const newWidth = (width | 0) + 2 * pad;
        const newHeight = (height | 0) + 2 * pad;
        if(bbox && ((width === undefined) || (height === undefined) || (newWidth !== width) || (newHeight !== height))) {
            onSetSize(newWidth, newHeight);
        }
    }, [autoCalcSize, ref.current, lines, width, height]);

    return (<>
        {hover && !selected ? <SelectionShape
            x={x} y={y}
            width={width} height={height}
            draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            shape={SHAPE_MAP[shape] || SHAPE_MAP.default}
        />: null}
        <g className={className}
            onClick={onClick}
            onMouseEnter={onClick ? () => setHover(true) : undefined}
            onMouseLeave={onClick ? () => setHover(false) : undefined}
            transform={`translate(${(x + dx) | 0}, ${(y + dy) | 0})`}
            filter={isDragging ? 'url(#dropShadow)' : undefined}
        >
            {(width && height) ? ShapeType({width, height, shape, icon, pad}): null}
            {content ? (
                <g transform={`translate(${AdjustContent(shape, height, width, pad)})`}>
                    <text ref={ref} y=".9em" alignmentBaseline="top" >{
                        (lines || []).map((line, idx) => <tspan key={idx} x="0" dy={idx ? "1.2em" : ""}>{line}</tspan>)
                    }</text>
                </g>
            ) : null}
        </g>
        {selected ? <SelectionShape
            x={x} y={y}
            width={width} height={height}
            draggable={draggable} onDrag={onDrag} onDrop={onDrop}
            shape={SHAPE_MAP[shape] || SHAPE_MAP.default}
        />: null}
    </>);
}

function AdjustContent(shape, height, width, pad){
    return ADJUST_CONTENT.call(shape, pad);
}

function ShapeType({width, height, shape, icon, pad}){
    return SHAPE_TYPES.call(shape, {
        width, height, pad, icon
    }).filter(x => !!x).map(([Component, props], idx) => <Component key={idx} {...props} />);
}

const ADJUST_CONTENT = new TypesSet({
    "oval": (pad) => String(pad).concat(", ").concat(pad * .8),
    "diamond": (pad) => String(pad*2).concat(", ").concat(pad),
    "default": (pad) => String(pad).concat(", ").concat(pad),
}, {
    "rect": "default",
    "document": "default",
    "rect-bars": "default",
});


const SHAPE_TYPES = new TypesSet({
    "rect": ({width, height, icon, pad}) => [
        ["rect", {className: "shape", width, height}],
        icon ? [FontAwesomeIcon, {icon, width: "30", height: "30", x: "-20", y:"-20"}] : null,
    ],
    "diamond": ({width, height, pad}) => [
        ["path", {
            d: `M 0 ${height/2}, L ${width/2 + pad} -${height}, L ${width + pad*2} ${height/2}, L ${width/2 + pad} ${height*2} Z`,
            className: "shape"
        }]
    ],
    "rect-bars": ({width, height, icon, pad}) => [
        ["rect", {className: "squaredCorners", width, height}],
        ["rect", {className: "squaredCorners", width: width / 8, height, x: `-${width/8}`}],
        ["rect", {className: "squaredCorners", width: width / 8, height, x: `${width}`}],
        icon ? [FontAwesomeIcon, {icon, width: "30", height: "30", x: `-${width/5}`, y:"-20"}] : null,
    ],
    "document": ({width, height, pad}) => [
        ["path", {
            d: `M 0 0 L ${width} 0 L ${width} ${height} Q ${width * 0.75} ${height * 0.6} ${width/2} ${height} Q ${width/4} ${height * 1.4} 0 ${height} Z`,
            className: "shape"
        }],
    ],
    "oval": ({width, height, pad}) => {
        width *= .95;
        height *= 2;
        return [
            ["path", {
                d: `M ${width*.26},0 
                    C ${width*.11},0 0,${height*.11} 0,${height*.26} 
                    c 0,${height*.15} ${width*.11},${height*.26} ${width*.26},${height*.26} 
                    l ${width*.53},0 
                    c ${width*.15},0 ${width*.26},-${height*.11} ${width*.26},-${height*.26} 
                    C ${width*1.05},${height*.11} ${width*0.95},0 ${width*.8},0 
                    L ${width*.26},0 z`,
                className: "shape"
            }]
        ]
    },
    "default": ({width, height, pad}) => [
        ["rect", {className: "shape", width, height }]
    ],
    "circle": ({width, height, pad}) => [
        ["circle", {className: "shape", cx: width / 2, cy: height / 2, r: width / 2}],
    ],
    "message": ({width, height, pad}) => [
        ["path", {
            d: `M 0 0 L ${width} 0 L ${width} ${height} L ${width - width*0.4} ${height} L ${width - width*0.6} ${height + height*0.4} L ${width - width*0.6} ${height} L 0 ${height} Z`,
            className: "shape"
        }],
    ],
    "page": ({width, height, pad}) => [
        ["path", {
            d: `M 10 0 L ${width+width*0.05} 0 L ${width+width*0.05} ${height} L ${width*0.9 - width} ${height} L ${width*0.9 - width} ${height - height*0.6} Z`,
            className: "shape"
        }],
    ],
    "hexagon": ({width, height, pad}) => [
        ["path", {
            d: `M 0 0 L ${width+width*0.05} 0 L ${width+width*0.25} ${height/2} L ${width+width*0.05} ${height} L 0 ${height} L ${width*0.75-width} ${height/2} Z`,
            className: "shape"
        }],
    ],
});

const SHAPE_MAP = {
    "rect-bars": "rectangle2",
    "diamond": "diamond",
    "document": "document",
    "rect": "rectangle",
    "default": "rectangle"
}

export default Shape;