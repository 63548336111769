import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import SequenceApi from "../../../api/SequenceApi";
import FormComponent from '../../../components/FormComponent';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../../constants';
import parseFormDefinition from '../../../util/parseFormDefinition';


export const EditSequence = FormComponent({
    ...parseFormDefinition({
        page: 'EditSequence',
        title: t`Sequence`,
        schemaProps: {
            "title": ["${$isTruthy(name) ? name : '", t`Unnamed Sequence`, "'}"].join(''),
            "type": "object",
            "ui:newSection": true,
            ":classNames": "grid-2-columns",
        },
        schema: {
            name: {
                "map:field": "name",
                title: t`Name`,
                type: "string"
            },
            sampleNumber: {
                title: t`Sample Number`,
                type: 'string',
                "ui:field": "ComputedField",
                "jsonata": "$formatSequence(currentIndex, format)"
            },
            format: {
                "map:field": "format",
                title: t`Format`,
                type: "string"
            },
            currentIndex: {
                "map:field": "currentIndex",
                title: t`Current Index`,
                type: "number"
            },
            indexReset: {
                ":classNames": "from-col-1-size-2",
                "map:field": "indexResets",
                title: t`Sequence index resets if other elements change.`,
                type: "boolean"
            },
        },
    }),
    withoutNavigation: true,
    parseProps: ({ sequenceId, setSequenceId }) => ({
        id: sequenceId,
        setId: setSequenceId
    }),
    loadData: async ({ id }) => id === 'new' ? {} : SequenceApi.get({ id }),
    async onSubmit({ object }, { props: { id } }) {
        return await (id === 'new') ? (
            SequenceApi.create(object)
        ) : (
            SequenceApi.update(id, object)
        )
    },
    submitButtons: FORM_UPDATE_SUBMIT_BUTTON,
    renderFormSubmitted: () => (
        <Redirect to="../sequences" />
    )
});

export default EditSequence;